import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import { PurpleDivider } from "../../components/Various";
import { Navbar } from "../../components/Navbar";
// import Footer from "../../components/Footer";
import ReactGA from "react-ga4";
import {
  Card,
  PageHeader,
  Layout,
  Row,
  Col,
  Steps,
  Form,
  Input,
  Button,
  Space,
  Radio,
  Result,
  notification,
  Select,
  Dropdown,
} from "antd";
import { DownOutlined } from '@ant-design/icons';

import useAuth from "../../hooks/useAuth";
import useMclAuth from "../../hooks/useMclAuth";

import AnswerContext, { AnswerProvider } from "../../context/AnswerProvider";

import { Care, categoryToPage, Url } from "../../constants/Global";
import Missing from "../site/Missing";

import imgIncare from "../../assets/images/cards/incare.jpeg";
import imgSelfcare from "../../assets/images/cards/selfcare.jpeg";
import imgProvider from "../../assets/images/cards/providers.jpeg";
import surveyBg from "../../assets/images/testimonials-bubbles-bg.svg";
// import Sider from "antd/lib/layout/Sider";

// meta pixel integration
import MetaPixel from "../../api/metaPixel";

import B2bLogo from "../../components/B2bLogo";

import "./Survey.css";

import { mclOpen } from "../../api/Mcl";

const { Header, Content, Footer } = Layout;
//const { Meta } = Card;
const { Step } = Steps;
const { TextArea } = Input;

const { Option } = Select;

export const SurveyRoutes = () => {
  return (
    <AnswerProvider>
      <Routes>
        <Route exact path="/" element={<Survey />} />
        {/* <Route exact path="/:INVITATION_ID" element={<SurveyInvited />} /> */}
        <Route exact path="/:INVITATION_ID" element={<Survey />} />
        <Route exact path="/referral" element={<SurveyProvider />} />
        <Route path="/b2b/:B2B_KEY" element={<Survey />} />
      </Routes>
    </AnswerProvider>
  );
};

export const DoSurveyRoutes = () => {
  return (
    <AnswerProvider>
      <Routes>
        <Route exact path="/" element={<Survey />} />
        {/* <Route exact path="/:INVITATION_ID" element={<SurveyInvited />} /> */}
        <Route exact path="/:INVITATION_ID" element={<Survey />} />
        <Route exact path="/referral" element={<SurveyProvider />} />
        <Route path="/b2b/:B2B_KEY" element={<Survey />} />
      </Routes>
    </AnswerProvider>
  );
};


const Survey = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const mclAuth = useMclAuth();

  const { answer, setFirstName, setLastName, setReason, b2b, setB2b } = useContext(AnswerContext);
  const [page, setPage] = useState(0);
  const [errMsg, setErrMsg] = useState(null);
  const { B2B_KEY } = useParams();
  console.log("b2b_key:", B2B_KEY);

  useEffect(() => {
    mclAuth
    .get(Url.CAREGIVERS.concat("/", auth?.id))
      .then((res) => {
        setFirstName(res.data?.firstName || "");
        setLastName(res.data?.lastName || "");
        // console.log("response", res.data);
      })
      .catch((error) => {
        console.log("Survey: error retrieving lastName and firstName", error.message);
      });

    if (B2B_KEY) {
      console.log("B2B_KEY:", B2B_KEY);
      mclOpen
        .get(Url.B2B.concat("/", B2B_KEY))
        .then((res) => {
          console.log("b2b:", res.data);
          setB2b(res?.data?.key);
          if (!res?.data?.key) {
            setErrMsg('Invalid business to business availment code!');
          }
        }).catch(error => {
          setErrMsg(error.response.data.message);
        });
    }
  }, []);

  const showContent = (pageSelect) => {
    // console.log('answer:' + JSON.stringify(answer));
    switch (pageSelect) {
      case Care.Reason.INCARE:
        // console.log('showing in care');
        return <SurveyIncare />;
      case Care.Reason.SELFCARE:
        // console.log('showing selfcare');
        return <SurveySelfcare />;
      case Care.Reason.PROVIDER:
        // console.log('showing provider');
        return <SurveyProvider navbar={false} />;
      // TODO: INVITEDONLY survey
      // case Care.Reason.INVITEDONLY:
      //   // console.log('showing provider');
      //   return <SurveyInvitedOnly />;
      default:
        return <SurveyReason setPage={setPage} />;
    }
  };

  return (
    <Layout
      className="survey-layout"
      style={{ minHeight: "100vh", minHeight: "100dvh" }}
    >
      <Navbar signin={true} navigate={navigate} b2b={b2b}/>
      <MetaPixel />
      <Content className="p-6">
        <B2bLogo b2b={b2b} banner={true}/>
        <h1
          style={{
            fontSize: "xx-large",
            // fontSize: "3em",
            fontWeight: "bold",
            color: "#30225f",
          }}
        >
          What Type Account Do You Need?
        </h1>
        <div
          style={{ background: "#eef2f6", marginTop: "0rem", height: "flex" }}
        >
          {showContent(page)}
        </div>
        { errMsg ? 
          <div style={{display: "flex", justifyContent: "center"}}>
              <h1 style={{color:"red"}}>{errMsg}</h1>
          </div>
          : null 
        }
      </Content>
      {/* <section
        style={{
          // height: "100vh",
          // height: "100dvh",
          backgroundImage: { surveyBg },
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <section
          className="bg-[url('assets/images/about-us/header-bg.png')]
                min-h-[2rem]
                max-h-[12rem]
                bg-center
                bg-no-repeat
                bg-cover
                overflow-hidden
                flex
            "
        >
          <div className="lg:container lg:mx-auto px-4 flex flex-col justify-center whitespace-normal">
            <Row>
              <Col xs={24}>
                <h1
                  className="text-[4rem] text-white"
                  style={{ textShadow: "3px 3px 7px rgb(0 0 0 / 65%)" }}
                >
                  What Type of Care Account?
                </h1>
              </Col>
            </Row>
          </div>
        </section>
        <PurpleDivider />
        <div
          style={{ background: "#eef2f6", marginTop: "0rem", height: "flex" }}
        >
          {showContent(page)}
        </div>
        <Row
          style={{
            backgroundColor: "rgba(48, 34, 95, 70%)",
            textAlign: "center",
            position: "fixed",
            left: "0",
            bottom: 0,
            // height: "1rem",
            width: "100%",
            color: "white",
          }}
        >
          <h6>
            Copyright &copy; 2021, 2022{" "}
            <a href="https://mycarelinq.com" style={{ color: "#e0551b" }}>
              My CareLinq, Inc
            </a>
            . All Rights Reserved
          </h6>
        </Row>
      </section> */}
      <Footer className="block fixed w-full bottom-0 text-[0.75rem] text-center font-thin items-center content-center text-primary-purple h-[2rem] p-1.5">
        Copyright &copy; 2021, 2023{" "}
        <a href="https://mycarelinq.com">
          <strong>My CareLinq, Inc.</strong>
        </a>{" "}
        All Rights Reserved.
      </Footer>
    </Layout>
  );
};

const SurveyReason = ({ setPage }) => {
  const { answer, setReason } = useContext(AnswerContext);

  const handleClick = (e, value) => {
    setReason(value);
    setPage(value);
  };

  return (
    <>
      {/* <Row>
        <Col span={24}>
          <h1
            style={{
              display: "flex",
              fontSize: 28,
              justifyContent: "center",
            }}
          >
            How can we help you today?
          </h1>
        </Col>
      </Row> */}
      <Row style={{ height: "2rem" }} />
      <Row style={{ textAlign: "center" }} justify="space-evenly">
        {/* <Col flex={1} /> */}
        <Col
          style={{ padding: "2rem" }}
          xs={{ span: 20 }}
          sm={{ span: 10 }}
          md={{ span: 6 }}
        >
          <div onClick={(e) => handleClick(e, Care.Reason.INCARE)}>
            <Card
              className="dash-card min-h-[250px]"
              hoverable
              xs={{
                width: "calc(100vw * 80%)",
                height: "calc(100vw * 80% * 1.333",
              }}
              sm={{
                width: "calc(50vw * 80%)",
                height: "calc(100vw * 80% * 1.333",
              }}
              md={{
                width: "calc(33vw * 80%)",
                height: "calc(100vw * 80% * 1.333",
              }}
              // cover={<img alt="in care" src={imgIncare} />}
              bordered={false}
            >
              <h3 className="text-[1.5rem]"><br/><br/>I am caring for <br/>someone else</h3>
            </Card>
          </div>
        </Col>
        <Col
          style={{ padding: "2rem" }}
          xs={{ span: 20 }}
          sm={{ span: 10 }}
          md={{ span: 6 }}
        >
          <div onClick={(e) => handleClick(e, Care.Reason.SELFCARE)}>
            <Card
              className="dash-card min-h-[250px]"
              hoverable
              xs={{
                width: "calc(100vw * 80%)",
                height: "calc(100vw * 80% * 1.333",
              }}
              sm={{
                width: "calc(50vw * 80%)",
                height: "calc(100vw * 80% * 1.333",
              }}
              md={{
                width: "calc(33vw * 80%)",
                height: "calc(100vw * 80% * 1.333",
              }}
              // cover={<img alt="self care" src={imgSelfcare} />}
            >
              <h3 className="text-[1.5rem]"><br/>I am planning care and support for myself</h3>
            </Card>
          </div>
        </Col>
        {/* <Col
          style={{ padding: "2rem" }}
          xs={{ span: 20 }}
          sm={{ span: 10 }}
          md={{ span: 6 }}
        >
          <div onClick={(e) => handleClick(e, Care.Reason.PROVIDER)}>
            <Card
              className="dash-card"
              hoverable
              style={
                {
                  // width: 250,
                  // height: "33vh",
                }
              }
              xs={{
                width: "calc(100vw * 80%)",
                height: "calc(100vw * 80% * 1.333",
              }}
              sm={{
                width: "calc(50vw * 80%)",
                height: "calc(100vw * 80% * 1.333",
              }}
              md={{
                width: "calc(33vw * 80%)",
                height: "calc(100vw * 80% * 1.333",
              }}
              cover={<img alt="service provider" src={imgProvider} />}
            >
              <h3>I’m a service provider for my client</h3>
            </Card>
          </div>
        </Col> */}
      </Row>
    </>
  );
};

const SurveyIncare = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const content = [
    {
      title: "You",
      content: (
        <SurveyInputName
          current={currentStep}
          setCurrent={setCurrentStep}
          select={"incare"}
        />
      ),
    },
    {
      title: "In Care",
      content: (
        <SurveyIncareInfo
          current={currentStep}
          setCurrent={setCurrentStep}
          select={"incare"}
        />
      ),
    },
    {
      title: "Relation",
      content: (
        <SurveyRelation
          current={currentStep}
          setCurrent={setCurrentStep}
          select={"incare"}
        />
      ),
    },
    {
      title: "Needs",
      content: (
        <SurveyNeeds
          current={currentStep}
          setCurrent={setCurrentStep}
          select={"incare"}
        />
      ),
    },
    {
      title: "Save",
      content: (
        <SurveySubscribe
          current={currentStep}
          setCurrent={setCurrentStep}
          select={"incare"}
        />
      ),
    },
  ];

  return (
    <>
      <h1 style={{ textAlign: "center", color: "#30225f" }}>
        Caring for Someone Else
      </h1>
      <Row
        style={{
          height: "flex",
          background: "white",
          padding: "1rem 3rem",
          borderRadius: "1rem",
        }}
      >
        <Col flex="180px">
          <Steps
            direction="vertical"
            /*</>style={{ height: '600px' }}*/ current={currentStep}
          >
            {content.map((item, idx) => (
              <Step key={"step-" + idx} title={item.title} />
            ))}
            {/* <Step title="Info" description="Your name." />
                    <Step title="In Care Info" description="Details of the one being cared for." />
                    <Step title="Relation" description="Relationship to in care." />
                    <Step title="Needs" description="Your needs." />
                    <Step title="Subscribe" description="Free subscription." /> */}
          </Steps>
        </Col>
        <Col flex="auto">
          <Content
            style={{
              background: "white",
              //     margin: '24px 16px',
              //     padding: 24,
              //     minHeight: 400,
              //     height: '100vh'
              //     height: '100dvh'
            }}
          >
            {content[currentStep].content}
            {/* {currentStep === 0 ? <SurveyInputName current={currentStep} setCurrent={setCurrentStep} /> : <></>}
                    {currentStep === 1 ? <SurveyIncareInfo current={currentStep} setCurrent={setCurrentStep} /> : <></>} */}
          </Content>
        </Col>
        {/* <Layout> */}
        {/* </Layout> */}
      </Row>
    </>
  );
};

const SurveySelfcare = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const content = [
    {
      title: "You",
      content: (
        <SurveyInputName
          current={currentStep}
          setCurrent={setCurrentStep}
          select={"selfcare"}
        />
      ),
    },
    {
      title: "Needs",
      content: (
        <SurveyNeeds
          current={currentStep}
          setCurrent={setCurrentStep}
          select={"selfcare"}
        />
      ),
    },
    {
      title: "Info",
      content: (
        <SurveyOtherInfo
          current={currentStep}
          setCurrent={setCurrentStep}
          select={"selfcare"}
        />
      ),
    },
    {
      title: "Save",
      content: (
        <SurveySubscribe
          current={currentStep}
          setCurrent={setCurrentStep}
          select={"selfcare"}
        />
      ),
    },
  ];

  return (
    <>
      <h1 style={{ textAlign: "center", color: "#30225f" }}>
        Caring for Myself
      </h1>
      <Row
        style={{
          height: "flex",
          background: "white",
          padding: "3rem",
          borderRadius: "1rem",
        }}
      >
        <Col flex="180px">
          <Steps
            direction="vertical"
            /*</>style={{ height: '600px' }}*/ current={currentStep}
          >
            {content.map((item, idx) => (
              <Step key={"step-" + idx} title={item.title} />
            ))}
          </Steps>
        </Col>
        <Col flex="auto">
          <Content
            style={{
              background: "white",
            }}
          >
            {content[currentStep].content}
          </Content>
        </Col>
      </Row>
    </>
  );
};

const SurveyInputName = ({ current, setCurrent, select }) => {
  const { answer, setFirstName, setLastName } = useContext(AnswerContext);

  // console.log('name answer:', answer)

  const onFinish = (values) => {
    ReactGA.event({
      action: select + "_survey_action",
      category: "survey_category",
      label: "survey_label",
      value: current,
    });

    // console.log('name values:', values)
    setFirstName(values.firstname);
    setLastName(values.lastname);
    // console.log('Success:', values);
    // console.log('Answer:', JSON.stringify(answer));
    setCurrent(current + 1);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        firstname: answer.firstName,
        lastname: answer.lastName,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row>
        <Col span={1} />
        <Col span={16}>
          <h3>What's your name?</h3>
        </Col>
      </Row>
      <p />
      <Form.Item
        label="First Name"
        name="firstname"
        rules={[
          {
            required: true,
            message: "Please input your first name",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="lastname"
        rules={[
          {
            required: false,
            message: "Please input your last name",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" shape="round" htmlType="submit">
          Next
        </Button>
      </Form.Item>
    </Form>
  );
};

const SurveyIncareInfo = ({ current, setCurrent, select }) => {
  const { answer, setIncareFirstName, setIncareLastName } =
    useContext(AnswerContext);

  console.log("in care answer:", answer);

  const onFinish = (values) => {
    ReactGA.event({
      action: select + "_survey_action",
      category: "survey_category",
      label: "survey_label",
      value: current,
    });

    setIncareFirstName(values.firstname);
    setIncareLastName(values.lastname);
    // console.log('Success:', values);
    // console.log('Answer:', JSON.stringify(answer));
    setCurrent(current + 1);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        firstname: answer.incareFirstName,
        lastname: answer.incareLastName,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row>
        <Col span={1} />
        <Col span={16}>
          <h3>{answer.firstName}, tell us who you are caring for</h3>
        </Col>
      </Row>
      <p />
      <Form.Item
        label="First Name"
        name="firstname"
        rules={[
          {
            required: true,
            message: "Please input first name",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="lastname"
        rules={[
          {
            required: false,
            message: "Please input last name",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Space>
          <Button
            type="primary"
            shape="round"
            onClick={() => setCurrent((current) => current - 1)}
          >
            Previous
          </Button>
          <Button type="primary" shape="round" htmlType="submit">
            Next
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const SurveyRelation = ({ current, setCurrent, select }) => {
  const { answer, setIncareRelation } = useContext(AnswerContext);
  const [isError, setIsError] = useState(false);

  // console.log('relation answer:', answer)

  const handleClick = (e, value) => {
    setIncareRelation(parseInt(value));
    if (value > 0) {
      setIsError(false);
    }
  };

  const typeButton = (relation) => {
    return relation === answer.incareRelation ? "primary" : "default";
  };

  const onNext = () => {
    if (answer.incareRelation > 0) {
      ReactGA.event({
        action: select + "_survey_action",
        category: "survey_category",
        label: "survey_label",
        value: current,
      });

      setCurrent((current) => current + 1);
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      <Row>
        <Col span={1} />
        <Col>
          <span>
            <h3>How are you related to {answer.incareFirstName}?</h3>
          </span>
        </Col>
      </Row>
      <Row style={{ height: "30px" }} />
      <Row>
        <Col span={1} />
        <Col>
          <Space>
            <Button
              type={typeButton(Care.Relation.WIFE)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.WIFE);
              }}
            >
              Wife
            </Button>
            <Button
              type={typeButton(Care.Relation.HUSBAND)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.HUSBAND);
              }}
            >
              Husband
            </Button>
            <Button
              type={typeButton(Care.Relation.PARTNER)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.PARTNER);
              }}
            >
              Partner
            </Button>
          </Space>
        </Col>
      </Row>
      <Row style={{ height: "20px" }} />
      <Row>
        <Col span={1} />
        <Col>
          <Space>
            <Button
              type={typeButton(Care.Relation.MOM)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.MOM);
              }}
            >
              Mom
            </Button>
            <Button
              type={typeButton(Care.Relation.DAD)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.DAD);
              }}
            >
              Dad
            </Button>
            <Button
              type={typeButton(Care.Relation.SISTER)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.SISTER);
              }}
            >
              Sister
            </Button>
            <Button
              type={typeButton(Care.Relation.BROTHER)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.BROTHER);
              }}
            >
              Brother
            </Button>
          </Space>
        </Col>
      </Row>
      <Row style={{ height: "20px" }} />
      <Row>
        <Col span={1} />
        <Col>
          <Space>
            <Button
              type={typeButton(Care.Relation.AUNT)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.AUNT);
              }}
            >
              Aunt
            </Button>
            <Button
              type={typeButton(Care.Relation.UNCLE)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.UNCLE);
              }}
            >
              Uncle
            </Button>
            <Button
              type={typeButton(Care.Relation.DAUGHTER)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.DAUGHTER);
              }}
            >
              Daughter
            </Button>
          </Space>
        </Col>
      </Row>
      <Row style={{ height: "20px" }} />
      <Row>
        <Col span={1} />
        <Col>
          <Space>
            <Button
              type={typeButton(Care.Relation.SON)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.SON);
              }}
            >
              Son
            </Button>
            <Button
              type={typeButton(Care.Relation.FRIEND)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.FRIEND);
              }}
            >
              Friend
            </Button>
            <Button
              type={typeButton(Care.Relation.OTHER)}
              shape="round"
              onClick={(e) => {
                handleClick(e, Care.Relation.OTHER);
              }}
            >
              Other
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={1} />
        <Col>
          {isError ? (
            <span style={{ color: "red" }}>
              A selection on relation is required
            </span>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row style={{ height: "30px" }} />
      <Row>
        <Col span={2} />
        <Col>
          <Space>
            <Button
              type="primary"
              shape="round"
              onClick={() => setCurrent((current) => current - 1)}
            >
              Previous
            </Button>
            {/* <Button type="primary" htmlType="submit"> */}
            <Button type="primary" shape="round" onClick={() => onNext()}>
              Next
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

const SurveyNeeds = ({ current, setCurrent, select }) => {
  const { answer, setNeed, /*setNeeds,*/ setNeedsTxt } =
    useContext(AnswerContext);

  const sc = answer.reason === Care.Reason.SELFCARE;

  const handleClick = (e, key) => {
    setNeed(key, !answer.needs[key]);
  };

  const typeButton = (key) => {
    return answer.needs[key] ? "primary" : "secondary";
  };

  const handleChange = (e) => {
    setNeedsTxt(e.target.value);
  };

  const question = sc
    ? `${answer.firstName}, what can we help you with? (Choose as many as you need)`
    : "What can we help you with? (Choose as many as you need)";

  return (
    <>
      <Row>
        <Col span={1} />
        <Col>
          <h3>{question}</h3>
        </Col>
      </Row>
      <Row style={{ height: "30px" }} />
      <Row>
        <Col span={1} />
        <Col>
          <Button
            type={typeButton("q1")}
            shape="round"
            onClick={(e) => handleClick(e, "q1")}
          >
            {sc ? (
              <>
                I need to prepare and plan for my future care and support needs
              </>
            ) : (
              <>Medical coordination support for the person I’m caring for</>
            )}
          </Button>
        </Col>
      </Row>
      <Row style={{ height: "20px" }} />
      <Row>
        <Col span={1} />
        <Col>
          <Button
            type={typeButton("q2")}
            shape="round"
            onClick={(e) => handleClick(e, "q2")}
          >
            {sc ? (
              <>
                I need to talk to someone about a situation that needs immediate
                attention
              </>
            ) : (
              <>
                Find personal care solutions and community support for the
                person I’m caring for
              </>
            )}
          </Button>
        </Col>
      </Row>
      <Row style={{ height: "20px" }} />
      <Row>
        <Col span={1} />
        <Col>
          <Button
            type={typeButton("q3")}
            shape="round"
            onClick={(e) => handleClick(e, "q3")}
          >
            {sc ? (
              <>
                Assistance navigating and analyzing public benefits and/or
                employer benefits
              </>
            ) : (
              <>Assistance with Legal, Financial and Benefit management:</>
            )}
          </Button>
        </Col>
      </Row>
      <Row style={{ height: "20px" }} />
      <Row>
        <Col span={1} />
        <Col>
          <Button
            type={typeButton("q4")}
            shape="round"
            onClick={(e) => handleClick(e, "q4")}
          >
            {sc ? (
              <>Assistance with budgeting for my care in the future</>
            ) : (
              <>
                Assistance with End-of Life care planning and After-Life plans
                for the person in care
              </>
            )}
          </Button>
        </Col>
      </Row>
      <Row style={{ height: "20px" }} />
      {sc ? (
        <Row>
          <Col span={1} />
          <Col>
            <Button
              type={typeButton("q5")}
              shape="round"
              onClick={(e) => handleClick(e, "q5")}
            >
              Assistance with End-of-Life and After-Life-Care preparation,
              planning, and research
            </Button>
          </Col>
        </Row>
      ) : (
        <Row />
      )}
      <Row style={{ height: "20px" }} />
      <Row>
        <Col span={1} />
        <Col span={16}>
          <p>Other:</p>
          <TextArea
            style={{ height: "10rem" }}
            showCount
            maxLength={255}
            onChange={(e) => handleChange(e)}
            value={answer.needsTxt}
          />
        </Col>
      </Row>
      <Row style={{ height: "30px" }} />
      <Row>
        <Col span={2} />
        <Col>
          <Space>
            <Button
              type="primary"
              shape="round"
              onClick={() => setCurrent((current) => current - 1)}
            >
              Previous
            </Button>
            {/* <Button type="primary" htmlType="submit"> */}
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                ReactGA.event({
                  action: select + "_survey_action",
                  category: "survey_category",
                  label: "survey_label",
                  value: current,
                });

                setCurrent((current) => current + 1);
              }}
            >
              Next
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

const SurveyOtherInfo = ({ current, setCurrent, select }) => {
  const { answer, setOtherInfo } = useContext(AnswerContext);

  const handleChange = (e) => {
    setOtherInfo(e.target.value);
  };

  return (
    <>
      <Row>
        <Col span={1} />
        <Col>
          <h3>
            Please share any additional information that you would like us to
            know
          </h3>
        </Col>
      </Row>
      <Row style={{ height: "30px" }} />
      <Row>
        <Col span={1} />
        <Col span={16}>
          <p>Info:</p>
          <TextArea
            style={{ height: "10rem" }}
            showCount
            maxLength={255}
            onChange={(e) => handleChange(e)}
            value={answer.otherInfo}
          />
        </Col>
      </Row>
      <Row style={{ height: "30px" }} />
      <Row>
        <Col span={2} />
        <Col>
          <Space>
            <Button
              type="primary"
              shape="round"
              onClick={() => setCurrent((current) => current - 1)}
            >
              Previous
            </Button>
            {/* <Button type="primary" htmlType="submit"> */}
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                ReactGA.event({
                  action: select + "_survey_action",
                  category: "survey_category",
                  label: "survey_label",
                  value: current,
                });
                setCurrent((current) => current + 1);
              }}
            >
              Next
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

const SurveySubscribev1 = ({ current, setCurrent, select }) => {
  const { answer, email, password, setEmail, setPassword, phone, setPhone, b2b, setB2b } =
    useContext(AnswerContext);
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);

  const [userTempEmail, setUserTempEmail] = useState("");
  const [userTempStripeID, setUserTempStripeID] = useState("");
  const [userTempSubID, setUserTempSubID] = useState("");
  const [userTempPlan, setUserTempPlan] = useState("");

  const [companies, setCompanies] = useState([]);

  const { INVITATION_ID, TEMP_USER_ID } = useParams();

  const DEFAULT_B2B_OTHER = 'other';

  // console.log('subscribe answer:', answer)

  useEffect(() => {
    if (submit) {
      doSubscribe();
      setSubmit(false);
    }
  }, [submit]);


  const doSubscribe = async () => {
    let payload = {
      email,
      firstName: answer.firstName,
      lastName: answer.lastName,
      password,
      phone,
      answer,
      invitationID: INVITATION_ID,
      StripeID: userTempStripeID,
      SubID: userTempSubID,
      Plan: userTempPlan,
      };
    
    if (b2b) {
      payload = {...payload, b2b: b2b}
    }

    try {
        const response = await mclOpen.post(
        Url.SUBSCRIBE,
        JSON.stringify(payload),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // TODO: remove console.logs before deployment
      console.log(JSON.stringify(response?.data));
      setPassword("");
      setSuccess(true);
    } catch (err) {
      if (!err?.response) {
        // setErrMsg('No Server Response');
        console.log("No Server Response");
        openNotification({
          message: "No Server Response",
          status: "500",
          title: "500",
          subTitle: "",
        });
      } else if (err.response?.status === 400) {
        //setErrMsg('Username Taken');
        // setErrMsg('Account with the same email already exists');
        console.log("Some fields need to be filled out");
        openNotification({
          message: "",
          status: "error",
          title: "Submission failed",
          subTitle:
            "Please check and modify the entered information before resubmitting.",
        });
      } else if (err.response?.status === 409) {
        //setErrMsg('Username Taken');
        // setErrMsg('Account with the same email already exists');
        console.log("Account with the same email already exists");
        openNotification({
          message: "",
          status: "error",
          title: "Submission failed",
          subTitle: "Account with the same email already exists.",
        });
      } else {
        // setErrMsg('Subscription Failed')
        console.log("Subscription Failed", err);
        openNotification({
          message: "Server Error",
          status: "500",
          title: "500",
          subTitle: "Sorry, something went wrong.",
        });
      }
    }
  };

  const onFinish = (values) => {
    ReactGA.event({
      action: select + "_survey_action",
      category: "survey_category",
      label: "survey_label",
      value: current,
    });

    console.log('b2b value:', b2b);
    setEmail(TEMP_USER_ID ? userTempEmail : values.email);
    setPassword(values.password);
    setPhone(values.phone);
    if (values.b2b !== DEFAULT_B2B_OTHER) {
      if (b2b !== values.b2b) {
        setB2b(values.b2b);
      }
    }

    //doSubscribe()
    setSubmit(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (success)
    return (
      <Result
        status="success"
        title="Successfully subscribed to a free MyCareLinq account!"
        subTitle={`An email with the confirmation link has been sent to ${email}.  Please click the link to complete the subscription`}
        extra={[
          <Button key="login" type="primary" shape="round">
            <Link to="/site/login">MyCareLinq Login</Link>
          </Button>,
        ]}
      />
    );

  return (
    <Form
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={
        {
          b2b: b2b,
          // firstname: answer.firstName,
          // lastname: answer.lastName,
        }
      }
      colon={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row>
        <Col span={1} />
        <Col span={16}>
          <h3>Subscribe to Our Free Service</h3>
        </Col>
      </Row>
      <p />
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: TEMP_USER_ID ? false : true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input
          initialvalues={TEMP_USER_ID ? userTempEmail : ""}
          placeholder={TEMP_USER_ID ? userTempEmail : "email"}
          disabled={TEMP_USER_ID}
        />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Must be at least 10 charaters long!",
            min: 10,
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[
          {
            required: false,
            message: "Please input your phone number!",
          },
        ]}
      >
        <Input placeholder="Phone number" />
      </Form.Item>


      <Form.Item
        name="b2b"
        label="Employer"
        rules={[
          {
            required: true,
            message: "Please select your employer!",
          },
        ]}
      >
        <Select defaultValue={b2b}  placeholder="Select your Employer">
          <Option value={DEFAULT_B2B_OTHER}>Other Employer</Option>
          {companies?.map((company, indexValue) => (
                      <Select.Option
                        key={indexValue}
                        value={company.key}
                      >
                        {company.label}
                      </Select.Option>
                    ))
          }
        </Select>
      </Form.Item>

      <Row>
        <Col span={1} />
        <Col span={16}>
          <h3>Your Details</h3>
        </Col>
      </Row>
      <p />
      <Form.Item
        label="Name"
        name="givername"
        rules={[
          {
            required: false,
            message: "Please input your first name",
          },
        ]}
      >
        {/* <Input disabled={true} /> */}
        <Button>
          {answer.firstName} {answer.lastName}
        </Button>
      </Form.Item>

      {answer.reason === Care.Reason.INCARE ? (
        <>
          <Row>
            <Col span={1} />
            <Col span={16}>
              <h3>In Care Details</h3>
            </Col>
          </Row>
          <p />
          <Form.Item
            label="Name"
            name="incarename"
            rules={[
              {
                required: false,
                message: "Please input your last name",
              },
            ]}
          >
            {/* <Input /> */}
            <Button>
              {answer.incareFirstName} {answer.incareLastName}
            </Button>
          </Form.Item>
        </>
      ) : (
        <></>
      )}

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Space>
          <Button
            type="primary"
            shape="round"
            onClick={() => setCurrent((current) => current - 1)}
          >
            Previous
          </Button>
          <Button type="primary" shape="round" htmlType="submit">
            Subscribe
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};


const SurveySubscribe = ({ current, setCurrent, select }) => {
  const { answer, 
          // email, password, setEmail, setPassword, phone, setPhone, b2b, setB2b 
        } =
    useContext(AnswerContext);
  const navigate = useNavigate();
  const mclAuth = useMclAuth();
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);

  // const [userTempEmail, setUserTempEmail] = useState("");
  // const [userTempStripeID, setUserTempStripeID] = useState("");
  // const [userTempSubID, setUserTempSubID] = useState("");
  // const [userTempPlan, setUserTempPlan] = useState("");

  // const [companies, setCompanies] = useState([]);

  // const { INVITATION_ID, TEMP_USER_ID } = useParams();

  // const DEFAULT_B2B_OTHER = 'other';

  // console.log('subscribe answer:', answer)

  useEffect(() => {
    if (submit) {
      doSave();
      setSubmit(false);
    }
  }, [submit]);

  useEffect(() => {
    // if (TEMP_USER_ID) {
    //   mclOpen
    //     .get(Url.PAYMENT.concat("/getUserTemp/", TEMP_USER_ID))
    //     .then((res) => {
    //       setUserTempEmail(res.data.email);
    //       setUserTempStripeID(res.data.stripeCustomerID);
    //       setUserTempSubID(res.data.subscriptionID);
    //       setUserTempPlan(res.data.currentPlan);
    //     });
    // }

    // // load employers list
    // mclOpen
    // .get(Url.B2B.concat("/"))
    // .then((res) => {
    //   console.log("companies:", res.data);
    //   const newCompanies = res.data.map(o => ( {label: o.description, key: o.key} ));
    //   setCompanies(newCompanies);
    //   //setCompanies(res.data);
    //   // setB2b(res?.data?.key);
    //   // if (!res?.data?.key) {
    //   //   setErrMsg('Invalid business to business availment code!');
    //   // }
    // }).catch(error => {
    //   // setErrMsg(error.response.data.message);
    // });

  }, []);

  const doSave = async () => {
    let payload = {
      // email,
      // firstName: answer.firstName,
      // lastName: answer.lastName,
      // password,
      // phone,
      answer,
      // invitationID: INVITATION_ID,
      // StripeID: userTempStripeID,
      // SubID: userTempSubID,
      // Plan: userTempPlan,
      };
    
    // if (b2b) {
    //   payload = {...payload, b2b: b2b}
    // }

    try {
        const response = await mclAuth.post(
        Url.SURVEY,
        JSON.stringify(payload),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // TODO: remove console.logs before deployment
      console.log(JSON.stringify(response?.data));
      // setPassword("");
      // setSuccess(true);
      navigate("/caregiver/dashboard");
    } catch (err) {
      if (!err?.response) {
        // setErrMsg('No Server Response');
        console.log("No Server Response");
        openNotification({
          message: "No Server Response",
          status: "500",
          title: "500",
          subTitle: "",
        });
      } else if (err.response?.status === 400) {
        //setErrMsg('Username Taken');
        // setErrMsg('Account with the same email already exists');
        console.log("Some fields need to be filled out");
        openNotification({
          message: "",
          status: "error",
          title: "Submission failed",
          subTitle:
            "Please check and modify the entered information before resubmitting.",
        });
      } else if (err.response?.status === 409) {
        //setErrMsg('Username Taken');
        // setErrMsg('Account with the same email already exists');
        console.log("Account with the same email already exists");
        openNotification({
          message: "",
          status: "error",
          title: "Submission failed",
          subTitle: "Account with the same email already exists.",
        });
      } else {
        // setErrMsg('Subscription Failed')
        console.log("Save Failed", err);
        openNotification({
          message: "Server Error",
          status: "500",
          title: "500",
          subTitle: "Sorry, something went wrong.",
        });
      }
    }
  };

  const onFinish = (values) => {
    ReactGA.event({
      action: select + "_survey_action",
      category: "survey_category",
      label: "survey_label",
      value: current,
    });

    ReactGA.event("sign_up");

    // console.log('b2b value:', b2b);
    // setEmail(TEMP_USER_ID ? userTempEmail : values.email);
    // setPassword(values.password);
    // setPhone(values.phone);
    // if (values.b2b !== DEFAULT_B2B_OTHER) {
    //   if (b2b !== values.b2b) {
    //     setB2b(values.b2b);
    //   }
    // }

    //doSubscribe()
    setSubmit(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // if (success)
  //   return (
  //     <Result
  //       status="success"
  //       title="Successfully subscribed to a free MyCareLinq account!"
  //       subTitle={`An email with the confirmation link has been sent to ${email}.  Please click the link to complete the subscription`}
  //       extra={[
  //         <Button key="login" type="primary" shape="round">
  //           <Link to="/site/login">MyCareLinq Login</Link>
  //         </Button>,
  //       ]}
  //     />
  //   );

  return (
    <Form
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={
        {
          // b2b: b2b,
          // firstname: answer.firstName,
          // lastname: answer.lastName,
        }
      }
      colon={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row className="mb-5"/>
      <Row className="mb-10">
        <Col span={20} className="text-center">
          <h3>
            Continue with Saving Info?
          </h3>
        </Col>
      </Row>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Space>
          <Button
            type="primary"
            shape="round"
            onClick={() => setCurrent((current) => current - 1)}
          >
            Previous
          </Button>
          <Button type="primary" shape="round" htmlType="submit">
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};


const SurveyProvider = ({ navbar = true }) => {
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(false);

  const [providerName, setProviderName] = useState("");
  const [providerEmail, setProviderEmail] = useState("");
  const [providerPhone, setProviderPhone] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");

  const [contactName, setContactName] = useState("");
  const navigate = useNavigate();

  // console.log('name answer:', answer)
  useEffect(() => {
    if (submit) {
      doReferral();
      setSubmit(false);
    }
  }, [submit]);

  const doReferral = async () => {
    try {
      const response = await mclOpen.post(
        Url.REFERRAL,
        JSON.stringify({
          providerName,
          providerEmail,
          providerPhone,
          clientName,
          clientEmail,
          clientPhone,
          contactName,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // TODO: remove console.logs before deployment
      console.log(JSON.stringify(response?.data));
      setSuccess(true);
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
        openNotification({
          message: "No Server Response",
          status: "500",
          title: "500",
          subTitle: "",
        });
      } else if (err.response?.status === 400) {
        console.log("Some referral fields need to be filled out");
        openNotification({
          message: "",
          status: "error",
          title: "Submission failed",
          subTitle:
            "Please check and modify the entered information before resubmitting.",
        });
      } else {
        console.log("Referral submission failed", err);
        openNotification({
          message: "Server Error",
          status: "500",
          title: "500",
          subTitle: "Sorry, something went wrong.",
        });
      }
    }
  };

  const onFinish = (values) => {
    console.log("provider values:", values);
    setProviderName(values.providername);
    setProviderEmail(values.provideremail);
    setProviderPhone(values.providerphone);
    setClientName(values.clientname);
    setClientEmail(values.clientemail);
    setClientPhone(values.clientphone);
    setContactName(values.contactname);
    // trigger submit
    setSubmit(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (success)
    return (
      <Result
        status="success"
        title="Successfully submitted a referral to MyCareLinq!"
        subTitle={`Thank you ${providerName} for your help`}
        extra={[
          <Button key="home" shape="round">
            <Link to="/">MyCareLinq Homepage</Link>
          </Button>,
          <Button type="primary" shape="round" key="another">
            <Link to="/survey">Another Referral</Link>
          </Button>,
        ]}
      />
    );

  return (
    <>
      {navbar ? <Navbar signin={true} navigate={navigate} /> : <></>}
      <Row style={{ background: "white", borderRadius: "1rem" }}>
        <Col span={4}></Col>
        <Col span={18}>
          <h1 style={{ textAlign: "center", color: "#30225f" }}>
            Service Provider
          </h1>
          <Content>
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                providername: providerName,
                clientname: clientName,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row style={{ height: "30px" }} />
              <Row>
                <Col span={1} />
                <Col span={16}>
                  <h2>Referral Information</h2>
                </Col>
              </Row>
              <Row style={{ height: "20px" }} />
              <Row>
                <Col span={1} />
                <Col span={16}>
                  <h2>Your details</h2>
                </Col>
              </Row>
              <Form.Item
                label="Your Name"
                name="providername"
                rules={[
                  {
                    required: true,
                    message: "Please input your name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="provideremail"
                label="E-mail"
                // hasFeedback
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: false,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="providerphone"
                label="Phone Number"
                dependencies={["provideremail"]}
                // hasFeedback
                rules={[
                  {
                    required: false,
                    message: "Please input your phone number!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      let pemail = getFieldValue("provideremail");
                      if (value || pemail) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("At least your email or password is required")
                      );
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>

              <Row style={{ height: "20px" }} />
              <Row>
                <Col span={1} />
                <Col span={16}>
                  <h2>Client details</h2>
                </Col>
              </Row>
              <Form.Item
                label="Client Name"
                name="clientname"
                rules={[
                  {
                    required: true,
                    message: "Please input client's name",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="clientemail"
                label="E-mail"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: false,
                    message: "Please input client E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="clientphone"
                label="Phone Number"
                dependencies={["clientphone"]}
                rules={[
                  {
                    required: false,
                    message: "Please input client phone number!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      let cemail = getFieldValue("clientemail");
                      if (value || cemail) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("At client email or password is required")
                      );
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>

              <Row style={{ height: "20px" }} />
              <Row>
                <Col span={1} />
                <Col span={16}>
                  <h2>MyCareLinq Contact (if any)</h2>
                </Col>
              </Row>
              <Form.Item
                label="Contact Name"
                name="contactname"
                rules={[
                  {
                    required: true,
                    message: "Please input your mycarelinq contact name",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" shape="round" htmlType="submit">
                  Submit Referral
                </Button>
              </Form.Item>
            </Form>
          </Content>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

const openNotification = ({ message, status, title, subTitle }) => {
  // console.log(message, title, status, subTitle)
  notification.open({
    message: message,
    description: (
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        /* extra={<Button type="primary">Back Home</Button>}*/
      />
    ),
  });
};

export default Survey;
