import React from "react";
import { Col, Row, Modal, Button } from "antd";

import "./PrivacyPolicy.css";
import { PurpleDivider } from "./Various";

const PolicyContent = ({ className = "", style }) => {
  const containerBaseClass =
    "lg:container lg:mx-auto p-5 text-center pp-content";
  const containerClass = className
    ? containerBaseClass + " " + className
    : containerBaseClass;

  return (
    <div style={style} className={containerClass}>
      <h2 className="text-h1">Privacy Policy</h2>
      <h6 className="text-h6" style={{ textAlign: "center" }}>
        Last Updated: June 30, 2022
      </h6>
      <p>
        My CareLinq, Inc. (“<strong>My CareLinq</strong>”, “<strong>we</strong>
        ,” or “<strong>us</strong>”) provides this Privacy Policy (“
        <strong>Policy</strong>”) to explain how we collect, use, disclose, and
        process information about you when you use our websites and other online
        products or services that link to this Privacy Policy (collectively, the
        “<strong>Services</strong>”).
      </p>
      <p>
        We may change this Policy from time to time. If we make changes, we will
        notify you by revising the date at the top of the Policy and, in some
        cases, we may provide you with additional notice (such as adding a
        statement to our homepage or sending you a notification). We encourage
        you to review the Policy whenever you access the Services or otherwise
        interact with us to stay informed about our information practices and
        the choices available to you.
      </p>
      <h3 className="text-h3">
        <strong>Information Collection</strong>
      </h3>
      <h6 className="text-h6">
        <i>Information You Provide to Us</i>
      </h6>
      <p>
        We collect information that you provide to us in the course of using our{" "}
        Services such as when you create an account, enter information into our{" "}
        Services or otherwise participate in any interactive features of the
        Services, fill out a form, subscribe to an email list or newsletter,
        provide us with feedback, request customer support, purchase a product
        or service, apply for a job we have posted, or otherwise communicate
        with us. The types of information that we may ask you to provide
        include, but are not limited to, your name, email address, address,
        phone number, date of birth, credit card or other payment information,
        and information regarding the care or other services you are
        coordinating or receiving, such as notes regarding current care needs,{" "}
        end-of-life or after-life plans. We also collect information any other{" "}
        information you provide to us or that you upload to or share using our
        Services.
      </p>
      <h6 className="text-h6">
        <i>Automatically Collected Information</i>
      </h6>
      <p>
        When you access or use our Services, we automatically collect{" "}
        information about you, including:
      </p>
      <ul>
        <li>
          <strong>Log Files and Usage Information</strong>: We may collect log
          files and usage information about your use of the Services, including{" "}
          IP address, time and date of access, which features or screens you
          access and for how long, webpages viewed, time spent in our
          application or a specific screen, interactions with other users and
          other usage information.
        </li>
        <li>
          <strong>Device Information</strong>: We may also collect information{" "}
          about the computer or mobile device you use to access our Services,{" "}
          including the hardware model, operating system and version, and/or{" "}
          unique device identifiers for device.
        </li>
        <li>
          <strong>
            Information Collected by Cookies and Other Tracking Technologies
          </strong>
          : Like most online services, we may use cookies and other
          technologies, such as web beacons, web storage, and unique advertising
          identifiers, to collect information about your activity, browser, and
          device. Cookies are small data files stored on your hard drive or in
          device memory that help us improve our Services, your experience, and
          to see which areas and features of our Services are popular by
          counting visits. Web beacons are electronic images that may be used on
          our Service or emails and which help deliver cookies, count visits and
          understand usage and campaign effectiveness.
        </li>
      </ul>
      <h6 className="text-h6">
        <i>Information We Collect From Other Sources</i>
      </h6>
      <p>
        We may also obtain information from other sources and combine that with
        information we collect through the Services, which may include{" "}
        information about you that our other users upload to or otherwise share
        using the Services. For example, if another user is helping manage care
        on your behalf and uploads, accesses, or stores notes about your care
        through the Services, My CareLinq collects that data. My CareLinq may
        also receive information about you from another user you are
        collaborating with, such as if another user enters your email address to
        invite you to use the Services.
      </p>
      <h3 className="text-h3">
        <strong>Use of Information</strong>
      </h3>
      <p>
        Information we collect about you is used to provide, maintain, and
        improve our Services, such as to enable your use of our Services.
      </p>
      <p>We also use the information we collect to:</p>
      <ul>
        <li>
          Send you technical notices, updates, security alerts, support, and
          administrative messages, and to respond to your comments, questions,{" "}
          and customer service requests;
        </li>
        <li>
          Process transactions, fulfill orders and send related information,{" "}
          including confirmations, receipts, and invoices;
        </li>
        <li>
          Communicate with you about products, services, offers, and events{" "}
          offered by us and others, and provide newsletters, marketing or{" "}
          promotional materials, and information we think will be of interest to
          you;
        </li>
        <li>
          Monitor and analyze trends, usage and activities in connection with{" "}
          our Services;
        </li>
        <li>
          Detect, investigate, and prevent fraudulent transactions and other{" "}
          illegal activities and to protect the rights and property of My
          CareLinq and others;
        </li>
        <li>
          Personalize and improve the Services and provide recommendations,{" "}
          advertisements, content or features that match your interests;
        </li>
        <li>
          Facilitate contests, sweepstakes, and promotions and process and{" "}
          deliver entries and rewards; and
        </li>
        <li>
          Carry out any other purpose described to you at the time the{" "}
          information was collected.
        </li>
      </ul>
      <h3 className="text-h3">
        <strong>Information Sharing</strong>
      </h3>
      <p>
        We share information about you as follows or as otherwise described in
        this Policy:
      </p>
      <ul>
        <li>
          With other users when you choose to do so by connecting your account{" "}
          with the accounts of other users (such as if you join a Care Circle){" "}
          or if you communicate with such other users using our Services;
        </li>
        <li>
          With vendors, business partners, consultants and other service{" "}
          providers who need access to such information to carry out work on our
          behalf including to facilitate operation, access and use of our{" "}
          Services, provide Services on our behalf, or assist us in analyzing{" "}
          how our Services are used;
        </li>
        <li>
          In response to a request for information if we believe disclosure is
          in accordance with, or required by, any applicable law or legal{" "}
          process, including lawful requests by public authorities to meet{" "}
          national security or law enforcement requirements;
        </li>
        <li>
          If we believe your actions are inconsistent with our user agreements
          or policies, or to protect the rights, property, and/or safety of My
          CareLinq, our users, or others;
        </li>
        <li>
          In connection with, or during negotiations of, any merger, sale of our
          assets, financing or acquisition of all or a portion of our business{" "}
          by another company;
        </li>
        <li>
          Between and among My CareLinq and our current and future parents,{" "}
          affiliates, subsidiaries and other companies under common control and{" "}
          ownership; and
        </li>
        <li>
          With your consent or at your direction, or at the direction of the{" "}
          individual(s) managing your care or other services using our Services.
        </li>
      </ul>
      <p>
        We may also share aggregated or de-identified information, which cannot{" "}
        reasonably be used to identify you, with other third parties.
      </p>
      <h3 className="text-h3">
        <strong>Your Choices</strong>
      </h3>
      <h6 className="text-h6">
        <i>Account Information</i>
      </h6>
      <p>
        You may have the ability to review, update, correct, or delete the{" "}
        information provided in your account registration, or that you have{" "}
        otherwise provided to us, by accessing your account or by contacting us
        at support@mycarelinq.com. Please note that we may retain certain
        information as required by law or for legitimate business purposes. We
        may also retain cached or archived copies of information about you for a
        certain period of time.
      </p>
      <h6 className="text-h6">
        <i>Cookies</i>
      </h6>
      <p>
        Most web browsers are set to accept cookies by default. If you prefer,
        you can usually choose to set your browser to remove or reject browser
        cookies. Please note that if you choose to remove or reject cookies,
        this could affect the availability or functionality of our Services.
      </p>
      <h6 className="text-h6">
        <i>Promotional Communications</i>
      </h6>
      <p>
        You may opt-out of receiving promotional emails from us by following the
        instructions at the bottom of those emails or using the settings{" "}
        available by accessing your account. If you opt-out, we may still send{" "}
        you non-promotional emails, such as those about your account or our{" "}
        ongoing business relations.
      </p>
      <h3 className="text-h3">
        <strong>Other Important Details</strong>
      </h3>
      <h6 className="text-h6">
        <i>Advertising and Analytics Services Provided by Others</i>
      </h6>
      <p>
        We may allow others to provide analytics services and serve{" "}
        advertisements on our behalf across the internet. These entities may use
        cookies, web beacons, device identifiers and other technologies to{" "}
        collect information about your use of the Services and other websites{" "}
        and applications, including your IP address, web browser, pages viewed,{" "}
        time spent on pages, links clicked and conversation information. This{" "}
        information may be used by My CareLinq and others to, among other
        things, analyze and track data, determine the popularity of certain
        content, deliver advertising and content targeted to your interests on
        our Services and other websites and better understand your online
        activity. For example, we use Google Analytics. See Google’s privacy
        policy for more information about how Google collects and uses data
        about you when you use our websites. To prevent your data from being
        used by Google Analytics, you can install Google’s opt-out browser
        add-on. For information on how our advertising partners may allow you to
        opt out of receiving ads based on your web browsing history, please
        visit{" "}
        <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>.
      </p>
      <p>
        <strong>Advertising</strong>: We may also use interest-based or audience
        advertising services provided by certain platforms. As part of these ad
        programs, we upload a list of email addresses to a third-party platform
        (such as Facebook, Instagram, or Twitter) so that we can display{" "}
        interest-based ads within that platform to specific groups of users or
        to groups of users with similar interests. You can opt out of receiving
        these types of ads from us and similar tailored audience ads in other
        third-party platforms that you may be using by accessing the ad setting
        features on such platforms.
      </p>
      <h6 className="text-h6">
        <i>Any Questions?</i>
      </h6>
      <p>
        This privacy policy applies to My CareLinq, Inc. If you have any{" "}
        questions about this Policy, please contact us at privacy@mycarelinq.com
        or at 2342 Shattuck Ave., #336, Berkeley, CA 94704.
      </p>
    </div>
  );
};

const PrivacyPolicy = () => {
  return (
    <div>
      <section
        className="
                bg-[url('assets/images/about-us/header-bg.png')] 
                min-h-[2rem]
                max-h-[12rem]
                bg-center 
                bg-no-repeat 
                bg-cover 
                overflow-hidden 
                flex
            "
      >
        <div className="lg:container lg:mx-auto px-4 flex flex-col justify-center whitespace-normal">
          <Row>
            <Col xs={24}>
              <h1
                className="text-[4rem] text-white"
                style={{ textShadow: "3px 3px 7px rgb(0 0 0 / 65%)" }}
              >
                PRIVACY POLICY
              </h1>
            </Col>
          </Row>
        </div>
      </section>
      <PurpleDivider />
      <PolicyContent />
      {/* <Container className="mt-3">
                <h1>[TODO: Insert reCAPTCHA here]</h1>
            </Container> */}
      {/* <Container>
                <Form className="mt-3">
                    <Form.Check
                        type="checkbox"
                        label="I am 18 years or older. I accept the terms in the Terms of Use."
                    />
                </Form>
                <Button>Confirm</Button>
            </Container> */}
    </div>
  );
};

const PrivacyPolicyModal = ({ isVisible, handleOk, handleCancel }) => {
  return (
    <Modal
      title="Privacy Policy"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
      className="privacy-policy-modal"
    >
      <PolicyContent className="overflow-hidden" />
    </Modal>
  );
};

export { PolicyContent, PrivacyPolicy, PrivacyPolicyModal };
