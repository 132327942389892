import React, { useState, useCallback, useEffect } from "react";
import ReactGA from "react-ga4";
import { Carousel, Card, Col, Row, Divider, Button, Modal, Form } from "antd";
import { CloudDownloadOutlined, MailOutlined, CalendarOutlined, StarOutlined, TeamOutlined } from "@ant-design/icons";
import { useNavigate,  useLocation } from "react-router";
import _ from "lodash";
import "./HomeComponents.css";
import Betty from "../assets/images/Betty.jpg";
import Gloria from "../assets/images/Gloria.png";
import Andrew from "../assets/images/Andrew.png";
import Care4Other from "../assets/images/platform/caregiver-for-others.png";
import SelfCare from "../assets/images/platform/self-care.png";
import CareTeam from "../assets/images/platform/care-team.png";
import HelpingGrandma from "../assets/images/why/helping-grandma.jpg";
import MCLAffordable from "../assets/images/why/MCL-is-Affordable.jpg";
import MCLSupport from "../assets/images/why/MCL-support.jpg";
import CaregiverCover from "../assets/images/caregiver-cover.png";
import SelfCareCover from "../assets/images/self-care-cover.png";
import CaregiverPlan from "../assets/articles/My-CareLinq-Caregiver-Checklist.pdf";
import { Link } from "react-router-dom";
import { ScheduleModal } from "./ScheduleForm";
import { PartnerModal, PartnerForm } from "./PartnerForm";
import DownloadArticle from "./DownloadArticle";
import { DownloadModal } from "./DownloadForm";
import GetStartedButton from "./GetStartedButton";
import { openNotification, PurpleDivider } from "./Various";

import { mclOpen } from "../api/Mcl";
import { Url } from "../constants/Global";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { RiArrowGoBackFill } from "react-icons/ri";

// import HomeHero from "../assets/images/home-hero.png";
// import HomeHero from "../assets/images/library/caring.jpg";
import HomeHero from "../assets/images/home-hero2.png";
import BetterCare from "../assets/images/home-affordable-adobestock-398022002.jpg";
import { fromJSON } from "postcss";
import HomeInTouch from "../assets/images/home-intouch.jpg";

const { Meta } = Card;

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
};

const CarouselSettings = {
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const Container = ({ className = "", fluid = false, style, children }) => {
  let containerClass = fluid ? "w-full px-3 mx-auto" : "container";
  return (
    <div
      className={containerClass + (className ? " " + className : "")}
      style={style}
    >
      {children}
    </div>
  );
};

//
// Hero section
//
const Hero = () => {

  const [scheduleModalSetting, setScheduleModalSetting] = useState({
    title: "Schedule a Free Consultation",
    isModalVisible: false,
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("needHelpForm");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const submitScheduleForm = async (data) => {
    setSubmitting(true);
    ReactGA.event({
      category: "home",
      action: "submit_form",
      label: "schedule_form",
    });
    let recaptchaToken = await handleReCaptchaVerify();
    if (!_.isEmpty(recaptchaToken)) {
      // Get the recaptcha token, submit it with the data to the backend
      mclOpen
        .post(Url.FORMS.concat("/", "schedule"), data, {
          headers: { "Content-Type": "application/json" },
          params: {
            token: recaptchaToken,
          },
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            if (
              !_.isEmpty(res.data) &&
              _.includes(res.data, "https://calendly.com")
            ) {
              window.open(res.data, "_blank");
            } else {
              openNotification({
                message: "Sorry",
                status: "500",
                title: "Something goes wrong in the server",
                subTitle: "Please wait for awhile and try again.",
              });
            }
          } else {
            openNotification({
              message: "Sorry",
              status: res.status ? res.status : "500",
              title: res.response.data,
            });
          }
          setScheduleModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
          setSubmitting(false);
          form.resetFields();
        })
        .catch((error) => {
          openNotification({
            message: "Sorry",
            status: error.status ? error.status : "500",
            title: error.response.data,
          });
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Failed to get Recaptcha token",
      });
      setSubmitting(false);
    }
  };

  return (
    <>
      <ScheduleModal
        setting={scheduleModalSetting}
        isSubmitting={isSubmitting}
        handleCancel={() => {
          ReactGA.event({
            category: "home",
            action: "close_modal",
            label: "schedule_modal",
          });
          setScheduleModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submitForm={submitScheduleForm}
        form={form}
      />
      <div
        className="
        mt-4
        sm:p-4
        color-white
        bg-nav-white
        left-0
        w-full
        min-h-[500px]"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem 2rem 2rem 2rem",
        }}
      >
        <Container
          style={{ maxWidth: "1200px" }}
          className="px-2 sm:px-3 md:px-4 lg:px-5 xl:px-6"
        >
          {/* <h1 className="text-primary-purple text-center xs:text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] lg:text-[2.25rem]">
            Collaborative Care and Support Management
          </h1>
          <p className="text-primary-purple text-center xs:text-[0.8rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.5rem]">
            My CareLinq offers a collaborative care and support management
            platform so that you do not have to do this alone. We deliver
            emotional and guided support so that you have more time to focus on
            caring for your loved one.
          </p> */}
          <Row justify="space-evenly" className="text-center">
            {/* For Caregiver column */}
            <Col sm={20} md={10} className="m-3">
              <h1 className="text-primary-purple text-center xs:text-[1.5rem] sm:text-[1.75rem] md:text-[1.5rem] lg:text-[2.25rem]">
                For Caregivers
              </h1>
              <p className="text-primary-purple text-center xs:text-[0.8rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.5rem]">
                Create a Care Management Plan for Your Loved One and a Caregiver
                Support Plan.
              </p>
              {/* <Button
                // className="platform-for-partner-link h-full"
                type="primary"
                onClick={() => {
                  ReactGA.event({
                    category: "home",
                    action: "open_modal",
                    label: "download_modal",
                  });
                  setDownloadModalSetting((prevState) => ({
                    ...prevState,
                    isModalVisible: true,
                  }));
                }}
                ghost
                aria-controls="download-caregiver-plan"
                aria-expanded={1}
                size="md"
              >
                Download Free Care Plan Template
              </Button> */}
            </Col>
            <Col sm={20} md={10} className="m-3">
              <h1 className="text-primary-purple text-center xs:text-[1.5rem] sm:text-[1.75rem] md:text-[1.5rem] lg:text-[2.25rem]">
                For Yourself
              </h1>
              <p className="text-primary-purple text-center xs:text-[0.8rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.5rem]">
                Create Your Plan, Make Your Care Wishes Known, and Leave
                Instructions for Your Care Team
              </p>
              {/* <Button
                // className="platform-for-partner-link h-full"
                type="primary"
                onClick={() => {
                  ReactGA.event({
                    category: "home",
                    action: "open_modal",
                    label: "download_modal",
                  });
                  setDownloadModalSetting((prevState) => ({
                    ...prevState,
                    isModalVisible: true,
                  }));
                }}
                ghost
                aria-controls="download-self-care-plan"
                aria-expanded={1}
                size="md"
              >
                Download Free Self-Care Template
              </Button> */}
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div
        className="
  mt-2
  sm:p-2
  color-white
  bg-nav-purple
  absolute
  bottom-0
  left-0
  w-full
  max-h-[flex]"
      >
        <Container fluid className="px-0">
          <Row>
            <Col xs={24} className="ml-0">
              <Container
                className="text-center px-1 py-1 sm:px-3 lg:pt-1 xl:pt-2 lg:max-w-[75%]"
                fluid
              >
                <p className="text-white text-center xs:text-[0.5rem] sm:text-[0.6rem] md:text-[0.8rem] lg:text-[1.25rem]">
                  We are on a mission to reduce caregiver stress and burnout.
                </p>
                <p className="text-white text-center xs:text-[0.5rem] sm:text-[0.6rem] md:text-[0.8rem] lg:text-[1.25rem]">
                  A platform made for everyone no matter your circumstance, age,
                  health status, or location.
                </p>
              </Container>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};
//
// WhoAreCaregivers section
//
const WhoAreCaregivers = () => {
  return (
    <>
      <div
        className="
        mt-0
        sm:p-4
        color-white
        bg-nav-white
        left-0
        w-full"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem 2rem 2rem 2rem",
        }}
      >
        <Container
          style={{ maxWidth: "1200px" }}
          // className="who-are-caregivers px-3 sm:px-4 md:px-5 lg:px-6 xl:px-8"
          className="px-5 sm:px-8 md:px-10 lg:px-12 xl:px-16"
        >
          <h1 className="text-primary-purple text-center xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem]">
            Who Are Caregivers?
          </h1>
          <p className="text-primary-purple text-center xs:text-[0.7rem] sm:text-[0.8rem] md:text-[1rem] lg:text-[1.25rem]">
            A <strong>caregiver</strong> is anyone who provides help to another
            person in need. Caregivers can be spouses, partners, children,
            siblings, cousins, aunts, uncles, other family members, friends,
            neighbors, co-workers, and anyone who wants to lend a hand. It's
            reported that 1 out of 3 adults fit this defintion.
          </p>
          <p className="text-primary-purple text-center xs:text-[0.7rem] sm:text-[0.8rem] md:text-[1rem] lg:text-[1.25rem]">
            Caregivers provide a wide variety of support and hands-on care.
            Caregivers provide emotional support, friendship, run errands,
            assist with <strong>activities of daily living</strong>, cook,
            clean, provide transportation, assist with home maintenance, manage
            paperwork, plan life events, and implement instructions and wishes.
          </p>
        </Container>
      </div>
    </>
  );
};

//
// SectionBanner definition
//
const SectionBanner = (props) => {
  return (
    <div
      className="w-full pt-3 pr-10 pb-2 pl-10 flex text-center items-center justify-center xs:text-[0.6rem] sm:text-[0.7rem] md:text-[0.85rem] lg:text-[1.35rem]"
      style={{
        backgroundColor: "rgba(48, 34, 95, 0.75)",
        color: "white",
      }}
    >
      <p style={{ maxWidth: "1200px" }}>{props.message}</p>
    </div>
  );
};

//
// Platform section
//
const Platform = ({ getStartedFunc = () => {} }) => {
  return (
    <div className="w-full px-3 mx-auto platform-container">
      <Row className="platform-header"></Row>
      <Row className="platform-overview">
        <Col
          xs={24}
          sm={8}
          className="platform-overview-LR flex justify-center items-center"
        >
          <h1>START WITH A PLAN</h1>
        </Col>
        <Col
          xs={24}
          sm={8}
          className="platform-overview-C flex justify-center items-center"
        >
          <h1>COLLABORATE WITH FAMILY &amp; FRIENDS</h1>
        </Col>
        <Col
          xs={24}
          sm={8}
          className="platform-overview-LR flex justify-center items-center"
        >
          <h1>CENTRALIZE &amp; STORE IMPORTANT DOCUMENTS</h1>
        </Col>
      </Row>
      <Row className="platform-body flex items-center">
        <Col xs={24} sm={16}>
          <div className="platform-body-L xl:container px-8">
            <h1 className="text-primary-purple font-medium text-h1">
              A PLATFORM MADE FOR EVERYONE
            </h1>
            <p className="text-primary-purple text-base pb-4">
              Whether you are an individual planning your own care, or a family
              member or friend giving care, My CareLinq is the resource to
              support you throughout the process of preparing and planning for
              aging at home, illness, end-of-life, and after-life care.
            </p>
            <GetStartedButton
              style={{ padding: "16px 24px", marginBottom: "24px" }}
              onClick={getStartedFunc}
            >
              Create Account
            </GetStartedButton>
          </div>
        </Col>
        <Col xs={24} sm={8} className="platform-body-R flex items-center">
          <ul className="list-disc">
            <li>Post hospital stay</li>
            <li>Advanced illness</li>
            <li>Aging at home</li>
            <li>End-of-life support</li>
            <li>After-life support</li>
            <li>Specialty services (legal, financial, benefits)</li>
            <li>Remote caregiving support</li>
            <li>Navigating benefits</li>
          </ul>
        </Col>
      </Row>
      <Row className="platform-overview">
        <Col
          xs={24}
          sm={8}
          className="platform-overview-LR flex justify-center items-center"
        >
          <h1>GET ORGANIZED</h1>
        </Col>
        <Col
          xs={24}
          sm={8}
          className="platform-overview-C flex justify-center items-center"
        >
          <h1>FIND ANSWERS &amp; SOLUTIONS</h1>
        </Col>
        <Col
          xs={24}
          sm={8}
          className="platform-overview-LR flex justify-center items-center"
        >
          <h1>CONNECT WITH THE RIGHT PEOPLE</h1>
        </Col>
      </Row>
    </div>
  );
};

//
// PlatformFor section
//
const PlatformFor = ({showContact=false}) => {
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  const [partnerModalSetting, setPartnerModalSetting] = useState({
    // title: "Partner with My CareLinq - Learn More",
    title: "Let's Work Together",
    isModalVisible: false,
  });
  const [downloadModalSetting, setDownloadModalSetting] = useState({
    title: "Download Request",
    isModalVisible: false,
  });
  // const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [partnerForm] = Form.useForm();
  const [downloadForm] = Form.useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [contact, setContact] = useState(showContact);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    // const token = await executeRecaptcha("needHelpForm");
    const token = await executeRecaptcha("partnerInquiryForm");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const submitPartnerForm = async (data) => {
    console.log(">>>Enter>>> submitPartnerForm");
    setSubmitting(true);
    ReactGA.event({
      category: "home",
      action: "submit_form",
      label: "partner_form",
    });
    let recaptchaToken = await handleReCaptchaVerify();
    if (!_.isEmpty(recaptchaToken)) {
      // Get the recaptcha token, submit it with the data to the backend
      mclOpen
        .post(Url.FORMS.concat("/", "partner-inquiry"), data, {
          headers: { "Content-Type": "application/json" },
          params: {
            token: recaptchaToken,
          },
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            openNotification({
              message: "Partner Inquiry submitted",
              status: "success",
              title: "Thank you " + data.firstName + "!",
              subTitle:
                "Thank you for your inquiry.  My CareLinq will contact you as soon as possible.",
            });
          } else {
            openNotification({
              message: "Sorry...partner inquiry encountered an error",
              status: res.status ? res.status : "500",
              title: res.response.data,
            });
          }
          setPartnerModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
          partnerForm.resetFields();
          setSubmitting(false);
        })
        .catch((error) => {
          openNotification({
            message: "Sorry...Partner Inquiry encountered a server error",
            status: error.status ? error.status : "500",
            title: error.response.data,
          });
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Partner Inquiry failed to get Recaptcha token",
      });
      setSubmitting(false);
    }
  };

  const submitDownloadForm = async (data) => {
    setSubmitting(true);
    ReactGA.event({
      category: "home",
      action: "submit_form",
      label: "download_form",
    });
    let recaptchaToken = await handleReCaptchaVerify();
    if (!_.isEmpty(recaptchaToken)) {
      // Get the recaptcha token, submit it with the data to the backend
      // mclOpen
      //   .post(Url.FORMS.concat("/", "partner-inquiry"), data, {
      //     headers: { "Content-Type": "application/json" },
      //     params: {
      //       token: recaptchaToken,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.status && res.status === 200) {
      //       openNotification({
      //         message: "Inquiry submitted",
      //         status: "success",
      //         title: "Thank you " + data.firstName + "!",
      //         subTitle:
      //           "Your partner inquiry has been submitted. We will follow up with you soon.",
      //       });
      //     } else {
      //       openNotification({
      //         message: "Sorry...partner inquiry encountered an error",
      //         status: res.status ? res.status : "500",
      //         title: res.response.data,
      //       });
      //     }
      //     setPartnerModalSetting((prevState) => ({
      //       ...prevState,
      //       isModalVisible: false,
      //     }));
      //     partnerForm.resetFields();
      //     setSubmitting(false);
      //   })
      //   .catch((error) => {
      //     openNotification({
      //       message: "Sorry...Partner Inquiry encountered a server error",
      //       status: error.status ? error.status : "500",
      //       title: error.response.data,
      //     });
      //     setSubmitting(false);
      //   })
      //   .finally(() => {
      //     setSubmitting(false);
      //   });
      <DownloadArticle article={CaregiverPlan} />;
    } else {
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Partner Inquiry failed to get Recaptcha token",
      });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (showContact) {
      setPartnerModalSetting((prevState) => ({
        ...prevState,
        isModalVisible: true,
      }));
      // setContact(false);
      navigate("/");
    }
  }, [showContact]);

  return (
    <>
      <PartnerModal
        setting={partnerModalSetting}
        isSubmitting={isSubmitting}
        handleCancel={() => {
          ReactGA.event({
            category: "home",
            action: "close_modal",
            label: "partner_modal",
          });
          setPartnerModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submitForm={submitPartnerForm}
        form={partnerForm}
      />

      <DownloadModal
        setting={downloadModalSetting}
        isSubmitting={isSubmitting}
        handleCancel={() => {
          ReactGA.event({
            category: "home",
            action: "close_modal",
            label: "download_modal",
          });
          setDownloadModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submitForm={submitDownloadForm}
        form={downloadForm}
      />

      {/* What We Offer section */}
      <div
        className={
          "w-full mt-0 mx-0 px-0 bg-purple-50 px-5 sm:px-8 md:px-10 lg:px-5 xl:px-15"
        }
        id="what-we-offer"
      >
        {/* <Row className="platform-header"></Row> */}
        <h1 className="text-primary-purple text-center xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] mt-0 pt-5">
          WHAT WE OFFER and HOW IT WORKS
        </h1>
        <div className="w-full mx-0 xs:px-2 px-0">
          <Row className="text-center justify-center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              className="py-5 lg:px-8 xl:px-15 px-10 text-primary-purple"
              id="for-family-caregivers"
            >
              <h3
                className="text-[#8159f5] text-[1.2rem] pb-2.5 mb-2"
                style={{ borderBottom: "#8159f555 solid 2px" }}
              >
                For <strong>Family</strong> Caregivers
              </h3>
              <h3 className="text-primary-purple">
                <strong>
                  Family Centered Care Team Management &amp; Support
                </strong>
              </h3>
              <p>
                Create a plan for the person you are caring for and invite other
                trusted caregivers so that everyone who needs to be involved can
                pitch in and help.
              </p>
              {/* <h3 className="text-primary-purple">
                <strong>Your Health and Wellness</strong>
              </h3>
              <p>Tools and on-demand support to make self-care a priority.</p> */}
              <h3 className="text-primary-purple">
                <strong>Expert Guidance, Get Help Fast</strong>
              </h3>
              <p>
                Speak with professionals who can help solve problems, complete
                urgent tasks, and provide ongoing support.
              </p>
              <h3 className="text-primary-purple">
                <strong>Learn Best Practices, Tips, and Resources</strong>
              </h3>
              <p>
                Get advice on how to be a family caregiver, get expert
                step-by-step guidance, checklists, find help when it's just too
                much.
              </p>
              <Button
                className="p-1"
                type="primary"
                shape="round"
                style={{
                  background: "#8159f5",
                  borderColor: "#8159f5",
                  color: "white",
                }}
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  ReactGA.event({
                    category: "home",
                    action: "button_click",
                    label: "download_checklist",
                  });
                  navigate("/article/my-carelinq-caregiver-checklist");
                  // setDownloadModalSetting((prevState) => ({
                  //   ...prevState,
                  //   isModalVisible: true,
                  // }));
                }}
                ghost
                aria-controls="download-caregiver-plan"
                aria-expanded={1}
                size="small"
              >
                Download Free Checklist
              </Button>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              className="py-5 lg:px-15 xl:px-20 px-10 text-primary-purple"
              id="for-yourself"
            >
              <h3
                className="text-[#8159f5] text-[1.2rem] pb-2.5 mb-2"
                style={{ borderBottom: "#8159f555 solid 2px" }}
              >
                For <strong>Yourself</strong>
              </h3>
              <h3 className="text-primary-purple">
                <strong>Your Plan, Your Way</strong>
              </h3>
              <p>
                Take control of your legacy and create a plan that reflects your
                wants and needs. We offer on-demand caregiver and planning
                services to help if you need support and guidance.
              </p>
              <h3 className="text-primary-purple">
                <strong>Make Your Wishes and Care Instructions Known</strong>
              </h3>
              <p>
                Document your wishes, leave instructions, and express gratitude
                to those who matter the most.
              </p>
              <h3 className="text-primary-purple">
                <strong>Personalize Your Care and Support Team</strong>
              </h3>
              <p>
                You can invite trusted team members to view and manage your care
                plan when you are no longer able.
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              className="py-5 lg:px-15 xl:px-20 px-10 text-primary-purple"
              id="for-partners"
            >
              <h3
                className="text-[#8159f5] text-[1.2rem] pb-2.5 mb-2"
                style={{ borderBottom: "#8159f555 solid 2px" }}
              >
                For <strong>Partners</strong>
              </h3>
              <h3 className="text-primary-purple">
                <strong>Exclusive Marketplace</strong>
              </h3>
              <p>
                My CareLinq will only partner with highly reputable vendors,
                community resources and providers who deliver.
              </p>
              <h3 className="text-primary-purple">
                <strong>Support Local</strong>
              </h3>
              <p>
                Enable and amplify community based organizations who are doing
                good work.
              </p>
              <h3 className="text-primary-purple">
                <strong>A Trusted Network</strong>
              </h3>
              <p>
                My CareLinq is on a mission to establish a highly specialized
                network of service providers and vendors.
              </p>
              <h3 className="text-primary-purple">
                <strong>Partnership</strong>
              </h3>
              <p>
                We want to partner with local and specialized community
                resources, vendors, providers, and referral networks.
              </p>
              <Button
                className="p-1"
                type="primary"
                shape="round"
                icon={<MailOutlined />}
                style={{
                  borderColor: "#8159f5",
                  color: "#8159f5",
                }}
                onClick={() => {
                  ReactGA.event({
                    category: "home",
                    action: "open_modal",
                    label: "platform_for_partner_modal",
                  });
                  // setPartnerModalSetting((prevState) => ({
                  //   ...prevState,
                  //   isModalVisible: true,
                  // }));
                  navigate(currentPath+"?model=partner");
                }}
                ghost
                aria-controls="partner-contact-us"
                aria-expanded={1}
                size="small"
              >
                Contact us to learn more
              </Button>
            </Col>
          </Row>
        </div>
        {/* <div className="w-full mx-0 px-2 platform-for-body-container">
          <Row gutter={16} className="platform-for-row flex">
            <Col
              xs={3}
              sm={4}
              md={6}
              lg={6}
              className="pt-5 platform-for-col-L"
            >
              <h1 className="text-h1 text-primary-purple">
                <strong>01</strong>
              </h1>
            </Col>
            <Col
              xs={21}
              sm={20}
              md={18}
              lg={18}
              className="pt-5 platform-for-col-RT"
            >
              <Row gutter={16}>
                <Col
                  xs={2}
                  sm={4}
                  md={6}
                  lg={6}
                  className="flex"
                  style={{ alignItems: "center" }}
                >
                  <img
                    src={Care4Other}
                    className="ml-0 pl-0"
                    style={{ height: "50%", filter: "opacity(0.2)" }}
                  ></img>
                </Col>
                <Col
                  xs={22}
                  sm={20}
                  md={18}
                  lg={18}
                  style={{
                    alignItems: "center",
                    paddingLeft: "0rem",
                    paddingRight: "3rem",
                  }}
                >
                  <h1 className="text-h1 text-primary-purple mb-0">
                    FOR <strong>FAMILY</strong> CAREGIVERS
                  </h1>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>You Health and Wellness</strong>
                  </h5>
                  <p className="text-primary-purple">
                    Tools and on-demand support to make self-care a priority.
                  </p>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>
                      Family-Centered Care Team Management &amp; Support
                    </strong>
                  </h5>
                  <p className="text-primary-purple">
                    Create plan for the person you are caring for and include
                    other trusted caregivers so that everyone who needs to be
                    involved can pitch in and help.
                  </p>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>
                      How to be a Caregiver, Learn Best Practives, Tips,
                      Resources
                    </strong>
                  </h5>
                  <p className="text-primary-purple">
                    Get advice on how to be a family caregiver, get expert
                    step-by-step guidance, checklists, find help when it's just
                    too much.
                  </p>
                  <Button
                    type="primary"
                    onClick={() => {
                      ReactGA.event({
                        category: "home",
                        action: "open_modal",
                        label: "download_modal",
                      });
                      setDownloadModalSetting((prevState) => ({
                        ...prevState,
                        isModalVisible: true,
                      }));
                    }}
                    ghost
                    aria-controls="download-caregiver-plan"
                    aria-expanded={1}
                    size="md"
                  >
                    Download Free Checklist
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16} className="platform-for-row-M flex">
            <Col
              xs={3}
              sm={4}
              md={6}
              lg={6}
              className="pt-5 platform-for-col-L"
            >
              <h1 className="text-h1 text-primary-purple">
                <strong>02</strong>
              </h1>
            </Col>
            <Col
              xs={21}
              sm={20}
              md={18}
              lg={18}
              className="pt-5 platform-for-col-RM"
              style={{ alignItems: "center" }}
            >
              <Row gutter={16}>
                <Col
                  xs={2}
                  sm={4}
                  md={6}
                  lg={6}
                  className="flex"
                  style={{ alignItems: "center" }}
                >
                  <img
                    src={SelfCare}
                    className="ml-0 pl-0"
                    style={{ height: "50%", filter: "opacity(0.2)" }}
                  ></img>
                </Col>
                <Col
                  xs={22}
                  sm={20}
                  md={18}
                  lg={18}
                  style={{
                    alignItems: "center",
                    paddingLeft: "0rem",
                    paddingRight: "3rem",
                  }}
                >
                  <h1 className="text-h1 text-primary-purple mb-0">
                    FOR <strong>YOURSELF</strong>
                  </h1>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>Your Plan, Your Way, For Your Care Team</strong>
                  </h5>
                  <p className="text-primary-purple">
                    Take control of your legacy and create a plan that reflects
                    your wants and needs. We offer on-demand caregiver and
                    planning services to help if you need support and guidance.
                  </p>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>
                      Make Your Wishes and Care Instructions Known
                    </strong>
                  </h5>
                  <p className="text-primary-purple">
                    Document your wishes, leave instructions, and express
                    gratitude to those who matter the most.
                  </p>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>Personalize Your Care and Support Team</strong>
                  </h5>
                  <p className="text-primary-purple">
                    You can invite trusted team members to view and manage your
                    care plan when you are no longer able.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16} className="platform-for-row flex">
            <Col
              xs={3}
              sm={4}
              md={6}
              lg={6}
              className="pt-5 platform-for-col-L"
            >
              <h1 className="text-h1 text-primary-purple">
                <strong>03</strong>
              </h1>
            </Col>
            <Col
              xs={21}
              sm={20}
              md={18}
              lg={18}
              className="pt-5 platform-for-col-RB"
              style={{ alignItems: "center" }}
            >
              <Row gutter={16}>
                <Col
                  xs={2}
                  sm={4}
                  md={6}
                  lg={6}
                  className="flex"
                  style={{ alignItems: "center" }}
                >
                  {" "}
                  <img
                    src={CareTeam}
                    className="ml-0 pl-0"
                    style={{ height: "50%", filter: "opacity(0.2)" }}
                  ></img>
                </Col>
                <Col
                  xs={20}
                  sm={20}
                  md={18}
                  lg={18}
                  style={{
                    alignItems: "center",
                    paddingLeft: "0rem",
                    paddingRight: "3rem",
                  }}
                >
                  <h1 className="text-h1 text-primary-purple mb-0">
                    FOR <strong>PARTNERS</strong>
                  </h1>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>Exclusive Marketplace</strong>
                  </h5>
                  <p className="text-primary-purple">
                    My CareLinq will only partner with highly reputable vendors,
                    community resources and providers who deliver.
                  </p>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>Support Local</strong>
                  </h5>
                  <p className="text-primary-purple">
                    Enable and amplify community based organizations who are
                    doing good work.
                  </p>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>A Trusted Network</strong>
                  </h5>
                  <p className="text-primary-purple">
                    My CareLinq is on a mission to establish a highly
                    specialized network of service providers and vendors.
                  </p>
                  <h5 className="text-h5 text-primary-purple mb-0">
                    <strong>Partnership</strong>
                  </h5>
                  <p className="text-primary-purple">
                    We want to partner with local and specialized community
                    resources, vendors, providers, and referral networks.{" "}
                    <span className="text-h4 text">
                      <Button
                        className="platform-for-partner-link h-full"
                        type="link"
                        onClick={() => {
                          ReactGA.event({
                            category: "home",
                            action: "open_modal",
                            label: "contact_modal",
                          });
                          setPartnerModalSetting((prevState) => ({
                            ...prevState,
                            isModalVisible: true,
                          }));
                        }}
                      >
                        Contact us to learn more
                      </Button>
                      .
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div> */}
      </div>
    </>
  );
};

//
// Jumbotron section
//
const Jumbotron = () => {
  return (
    <Carousel
      autoplay
      autoplaySpeed={7500}
      className="jumbotron-testimonial"
      style={{}}
    >
      <div className="jumbotron-testimonial-light">
        <div className="lg:container px-3 mx-auto mt-5">
          <h3 className="text-primary-purple xs:text-[0.7rem] sm:text-[0.8rem] md:text-[1rem] lg:text-[1.25rem]">
            My CareLinq
            <strong>
              {" "}
              empowers caregivers and individuals to take back their bodies,
              spirit, and wellbeing{" "}
            </strong>
            through technology-enabled human services and holistic care
            coordination.
          </h3>
          <div className="lg:container px-3 mx-auto">
            <Row className="mt-5 jumbotron-testimonial-col">
              <Col
                xs={{ order: 2 }}
                sm={{ order: 2 }}
                md={{ span: 16, order: 1 }}
                className="jumbotron-testimonial-col-L"
              >
                <div className="lg:container px-3 mx-auto">
                  <h2 className="text-primary-purple text-[1.5rem]">
                    What <strong>Betty</strong> had to say
                  </h2>
                  <h5 className="text-primary-purple text-[1rem]">
                    Excellent and timely follow up on the most challenging
                    questions about long distance caregiving.
                  </h5>
                </div>
              </Col>
              <Col
                xs={{ order: 1 }}
                sm={{ order: 1 }}
                md={{ span: 8, order: 2 }}
                className="jumbotron-testimonial-col-R"
              >
                <img src={Betty} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="jumbotron-testimonial-light">
        <div className="container px-3 mx-auto mt-5">
          <h3 className="text-primary-purple xs:text-[0.75rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.5rem]">
            <strong>My CareLinq recieved an NPS Score of 10/10</strong>.
          </h3>
          <div className="container px-3 mx-auto">
            <Row className="mt-5 jumbotron-testimonial-col">
              <Col
                xs={{ order: 2 }}
                sm={{ order: 2 }}
                md={{ span: 16, order: 1 }}
                className="jumbotron-testimonial-col-L"
              >
                <div className="lg:container px-3 mx-auto">
                  <h2 className="text-primary-purple text-[1.5rem]">
                    What <strong>Gloria</strong> had to say
                  </h2>
                  <h5 className="text-primary-purple text-[1rem]">
                    I referred a friend who is interested in finding out more
                    about planning ahead in anticipation of her parents needs.
                    My CareLinq provides really helpful guidance.
                  </h5>
                </div>
              </Col>
              <Col
                xs={{ order: 1 }}
                sm={{ order: 1 }}
                md={{ span: 8, order: 2 }}
                className="jumbotron-testimonial-col-R"
              >
                <img src={Gloria} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="jumbotron-testimonial-light">
        <div className="container px-3 mx-auto mt-5">
          <h3 className="text-primary-purple xs:text-[0.75rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.5rem]">
            My CareLinq is <strong>there</strong> for me.
          </h3>
          <div className="container px-3 mx-auto">
            <Row className="mt-5 jumbotron-testimonial-col">
              <Col
                xs={{ order: 2 }}
                sm={{ order: 2 }}
                md={{ span: 16, order: 1 }}
                className="jumbotron-testimonial-col-L"
              >
                <div className="container px-3 mx-auto">
                  <h2 className="text-primary-purple text-[1.5rem]">
                    What <strong>Andrew</strong> had to say
                  </h2>
                  <h5 className="text-primary-purple text-[1rem]">
                    Two days after my wife passed away, I vividly remember being
                    on the phone with various mortuaries and funeral homes
                    trying to finalize arrangements that we never planned for
                    during her illness. I was hardly in any position to make any
                    decision and was at the mercy of the people I was talking
                    with. My CareLinq can make this process easier and they're
                    there to help every step of the way.
                  </h5>
                </div>
              </Col>
              <Col
                xs={{ order: 1 }}
                sm={{ order: 1 }}
                md={{ span: 8, order: 2 }}
                className="jumbotron-testimonial-col-R"
              >
                <img src={Andrew} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

//
// WhyMCL section
//
const WhyMCL = ({ getStartedFunc = () => {} }) => {
  const [show, setShow] = useState(0);

  const readMoreOnClick = (key) => (event) => {
    setShow((prevShow) => (key === prevShow ? 0 : key));
  };

  return (
    <Carousel
      autoplay
      autoplaySpeed={10000}
      className="whyMCL"
      // arrows - not part of ANT Design components
      // {...CarouselSettings}
    >
      <div className="w-full px-3 mt-3">
        <div className="lg:container lg:mx-auto">
          <h1 className="text-h1 text-primary-purple mt-1 mr-3 mb-1 ml-3">
            WHY MY CARELINQ?
          </h1>
          <h4 className="text-h4 text-primary-purple mt-1 mr-3 mb-2 ml-3">
            WE'RE <strong>AFFORDABLE</strong>
          </h4>
          <Row className="whyMCL-col">
            <Col
              xs={{ order: 2 }}
              sm={{ order: 2 }}
              md={{ span: 13, order: 1 }}
              className="whyMCL-col-L"
            >
              <h6 className="text-h6 text-primary-purple mb-1">
                <strong>
                  My CareLinq helps alleviate the burden of managing a loved
                  one’s care needs by partnering with healthcare institutions
                  and providing additional transitional support and on-going
                  case management.{"  "}
                </strong>
                {/* <Button
                  type="primary"
                  ghost
                  onClick={readMoreOnClick(1)}
                  aria-controls="mcl-is-affordable"
                  aria-expanded={1}
                  size="sm"
                  >
                  {" "}
                  Read more...
                </Button> */}
              </h6>
              <p className="text-base text-primary-purple mt-3 whyMCL-affordable-card">
                Did you know Americans spent over $4 trillion for healthcare in
                2020? Or that, even with insurance, a one-night-stay in the
                hospital averages around $11,700? As more people get sick, age,
                or have a terminal diagnosis, our hospitals and providers are
                becoming overwhelmed dealing with non-medical and social needs.
                As the cost of care increases, insurance providers will continue
                to shift the cost to consumers. The ones left behind – the
                caregivers and survivors – are impacted the most. Research shows
                that not only can caregivers improve care recipients’ health,
                but they can also help reduce health care utilization and
                expenditure.
              </p>
            </Col>
            <Col
              xs={{ order: 1 }}
              sm={{ order: 1 }}
              md={{ span: 7, order: 2 }}
              className="whyMCL-col-R"
            >
              <img src={MCLAffordable} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="w-full px-3 mt-3">
        <div className="lg:container lg:mx-auto">
          <h1 className="text-h1 text-primary-purple mt-1 mr-3 mb-1 ml-3">
            WHY NOW?
          </h1>
          <h4 className="text-h4 text-primary-purple mt-1 mr-3 mb-2 ml-3">
            CAREGIVING HAS SIGNIFICANT <strong>PEOPLE IMPACT</strong>
          </h4>
          <Row className="whyMCL-col">
            <Col
              xs={{ order: 2 }}
              sm={{ order: 2 }}
              md={{ span: 13, order: 1 }}
              className="whyMCL-col-L"
            >
              <h6 className="text-h6 text-primary-purple mb-1">
                <strong>
                  Today, there are 53+ million unpaid caregivers in the United
                  States. More caregivers will be needed in the future as 71
                  million baby boomers will be over 65 years and older by 2030.
                  We not only care for older adults but we also care people who
                  are recovering from an injury, surgery, managing a disability,
                  or facing a chronic condition diagnosis.
                </strong>
              </h6>
              <div className="text-base text-primary-purple mt-3 whyMCL-affordable-card">
                <p>
                  Already, caregiving is reorganizing relations across American
                  households and communities as these responsibilities extend
                  into newer and younger generations. We find adults caring for
                  children and parents, grandchildren caring for grandparents
                  and vice versa, multiple people caring for an adult, and
                  neighbors and friends caring for each other. The demands on
                  caregivers are intense as they struggle to manage competing
                  demands on their time all the while striving to care for their
                  loved ones. As such, the needs of caregivers are complex,
                  diverse, and dynamic.
                </p>
                <p>
                  For this reason, we need to innovate new ways to care… for our
                  loved ones, for those giving care, and for each other. This
                  kind of care means giving caregivers the technical and
                  technological tools they need to carry out their everyday
                  responsibilities and feel confident in doing so. It means
                  personalizing solutions that are tailored and adapted to each
                  caregivers’ circumstances, recognizing that every person’s
                  needs (including that of the care recipient) are unique. It
                  means expanding caregivers’ social networks and support
                  systems so they do not feel alone in their journey but rather,
                  belong to a wider community of peers to whom they can turn to
                  for guidance. It means, most importantly, understanding
                  caregiving as a deeply meaningful experience for caregivers
                  and the ones for whom they care. The goal, therefore, is to
                  seek out ways to enhance that caregiving journey.
                </p>
              </div>
            </Col>
            <Col
              xs={{ order: 1 }}
              sm={{ order: 1 }}
              md={{ span: 7, order: 2 }}
              className="whyNow-col-R"
            >
              <img src={HelpingGrandma} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="w-full px-3 mt-3">
        <div className="lg:container lg:mx-auto">
          <h1 className="text-h1 text-primary-purple mt-1 mr-3 mb-1 ml-3">
            HOW DOES MY CARELINQ HELP?
          </h1>
          <h4 className="text-h4 text-primary-purple mt-1 mr-3 mb-2 ml-3">
            OFFERS <strong>NON-MEDICAL CASE MANAGEMENT</strong>
          </h4>
          <Row className="whyMCL-col">
            <Col
              xs={{ order: 2 }}
              sm={{ order: 2 }}
              md={{ span: 13, order: 1 }}
              className="whyMCL-col-L"
            >
              <h6 className="text-h6 text-primary-purple mb-1">
                <strong>
                  My CareLinq offers non-medical case management to caregivers
                  and self-managing adults. Our services cover a large portion
                  of supportive care across the care continuum. My CareLinq
                  supports caregivers and patients through all stages of life
                  regardless of age, location, and disease.
                </strong>
              </h6>
              <div className="text-base text-primary-purple mt-3 whyMCL-affordable-card">
                <p>
                  Did you know that medical care is estimated to account for
                  only 10-20% of modifiable contributors to healthy outcomes and
                  80-90% is related to behaviors, socioeconomic and
                  environmental factors?
                </p>
                <p>
                  A large portion of caregivers spend their time cajoling,
                  organizing, coordinating, and vetting resources outside of
                  institutional medical care. Food, shelter, social engagement,
                  transportation, life transitions, tracking of documents,
                  personal shopping and providing personal care are common
                  caregiver responsibilities.
                </p>
                <p>
                  My CareLinq offers tools and expert resources to help ease the
                  workload so that family caregivers can focus on what matters
                  most. Families and communities can chip in, and all
                  participate and support the care being provided.
                </p>
              </div>
            </Col>
            <Col
              xs={{ order: 1 }}
              sm={{ order: 1 }}
              md={{ span: 7, order: 2 }}
              className="MCLSupportModel-col-R"
            >
              <img src={MCLSupport} />
            </Col>
          </Row>
        </div>
      </div>
    </Carousel>
  );
};

const  Herov2 = () => {
  const navigate = useNavigate();

  return (
    <div 
      className="
        pt-20
        pb-20
        sm:p-4        
        bg-purple-50
        left-0
        w-full
        min-h-[500px]"
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        //   padding: "2rem 2rem 2rem 2rem",
        // }}
    >
      <Row className="mt-10 mb-20">
        <Col md={2}></Col>
        <Col md={10}>
          <Row>
            <h2 className="text-primary-purple text-left xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] mt-0 pt-5">
              Now your employees can excel at work, and at home.
            </h2>
          </Row>
          <Row>
            <h3 className="text-primary-purple text-left xs:text-[0.5rem] sm:text-[0.75rem] md:text-[1rem] lg:text-[1.5rem]">
              My CareLinq helps your employees care for loved ones so they get the best care
            </h3>
          </Row>
          <Row>
            <h4 className="text-primary-purple text-left xs:text-[0.4rem] sm:text-[0.5rem] md:text-[0.75rem] lg:text-[1.25rem]">
              <strong>
                <br/>
                1 in 4 employees are caregivers<br/><br/>
                Caregiving can take up to 26 hours per week<br/><br/>
                Our care consultants can save you $8,000 per employee
              </strong>
            </h4>
          </Row>
          <Row className="ml-20 mt-10">          
              <Button
                className="p-1"
                type="primary"
                shape="round"
                style={{
                  background: "#8159f5",
                  borderColor: "#8159f5",
                  color: "white",
                }}
                icon={<MailOutlined />}
                onClick={() => {
                  // ReactGA.event({
                  //   category: "home",
                  //   action: "button_click",
                  //   label: "download_checklist",
                  // });
                  // // navigate("/article/my-carelinq-caregiver-checklist");
                  // // setDownloadModalSetting((prevState) => ({
                  // //   ...prevState,
                  // //   isModalVisible: true,
                  // // }));
                  // setScheduleModalSetting((prevState) => ({
                  //   ...prevState,
                  //   isModalVisible: true,
                  // }));

                  // window.open("https://calendly.com/mycarelinq", "_blank");
                  navigate("/?modal=partner");
                }}
                ghost
                aria-controls="download-caregiver-plan"
                aria-expanded={1}
                size="large"
              >
                Learn More
              </Button>
            </Row>
        </Col>
        <Col md={2}/>
        <Col md={8}
                // className="jumbotron-testimonial-col-R"
                // xs={{ order: 1 }}
                // sm={{ order: 1 }}
                // md={{ span: 12, order: 2 }}
                >
          <section
            className="bg-cover bg-no-repeat bg-center overflow-hidden relative"
            style={{
              minHeight: "600px",
              height: "flex",
              // linearGradient: "rgba(255,255,255,1)",
              borderRadius: "5%",
              backgroundImage: `url(${HomeHero})`,
            }}
            id="home"
          />
          {/* <img src={HomeHero} /> */}

        </Col>
        <Col md={2}></Col>
      </Row>
    </div>
  );
}

const  Benefits = () => {
  return (
    <div 
      className="
        pt-20
        pb-20
        sm:p-4        
        bg-purple-50
        left-0
        w-full
        min-h-[400px]"
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "center",
        //   padding: "2rem 2rem 2rem 2rem",
        // }}
    >
      <Row className="mt-10 mb-20">
        <Col md={2}></Col>
        <Col md={8}
                // className="jumbotron-testimonial-col-R"
                // xs={{ order: 1 }}
                // sm={{ order: 1 }}
                // md={{ span: 12, order: 2 }}
                >
          <section
            className="bg-cover bg-no-repeat bg-center overflow-hidden relative"
            style={{
              minHeight: "700px",
              height: "flex",
              // linearGradient: "rgba(255,255,255,1)",
              borderRadius: "5%",
              backgroundImage: `url(${BetterCare})`,
            }}
            id="home"
          />
          {/* <img src={HomeHero} /> */}

        </Col>
        <Col md={1}/>
        <Col md={11}>
          <Row>
            <h2 className="text-primary-purple text-left xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] mt-0 pt-5">
              Better care, lower cost, peace of mind
            </h2>
          </Row>
          <Row>
            <h4 className="text-primary-purple text-left xs:text-[0.4rem] sm:text-[0.5rem] md:text-[0.75rem] lg:text-[1.25rem]">
              When your employee gets the call that something's happened to Mom, it can be life changing.  But most 
              employees aren't equipped to handle what's next.
              <br/><br/>
              That's where My CareLinq steps in.  We build a strategic care plan, source the care team and services,
              and manage care so your employees can spend meaningful time with their families and stay
              productive at work.
            </h4>
          </Row>
          <Row>
            <h3 className="text-primary-purple text-left xs:text-[0.5rem] sm:text-[0.75rem] md:text-[1rem] lg:text-[1.5rem]">
              <strong>Key Benefits for Employers</strong>
            </h3>
            <br/>
            <h4 className="text-primary-purple text-left xs:text-[0.4rem] sm:text-[0.5rem] md:text-[0.75rem] lg:text-[1.25rem]">
              <Row>
                <Col md={2}></Col>
                <Col md={22}>
                  <ul class="bio">
                    <li>Lower absenteeism and presenteeism</li>
                    <li>Help your employees stay focused</li>
                    <li>Differentiate your benefits</li>
                  </ul>
                </Col>
              </Row>
            </h4>
          </Row>
          <Row>
            <h3 className="text-primary-purple text-left xs:text-[0.5rem] sm:text-[0.75rem] md:text-[1rem] lg:text-[1.5rem]">
              <strong>Measures of success</strong>
            </h3>
            <br/>
            <h4 className="text-primary-purple text-left xs:text-[0.4rem] sm:text-[0.5rem] md:text-[0.75rem] lg:text-[1.25rem]">
              My CareLinq can save employees up to 20 hours a week and $7,200 in out of pocket expenses,
              reduce stress, and provide better care for loved ones.
            </h4>
          </Row>
        </Col>
        <Col md={2}></Col>
      </Row>
    </div>
  );
}

const CaseStudies = () => {
  const navigate = useNavigate();

  return (
    <div>
      {/* <section className="bg-[url('assets/images/about-us/background.png')] min-h-[300px] bg-center bg-no-repeat bg-cover overflow-hidden flex">
        <Row>
          <Col md={12}>
          </Col>
          <Col md={12}>
          </Col>
        </Row>
      </section> */}
      <section>
        <div 
        // className="pl-10 pr-10 min-h-[275px] w-full justify-center text-white"
                className="min-h-[200px] w-full text-white text-center"
                style={{
                  backgroundColor: "rgba(48, 34, 95, 0.75)",
                  // color: "black",
                  // borderTopLeftRadius: "5%",
                  // borderBottomLeftRadius: "5%",
                }}
              >
          <Row>
            <Col md={5}/>
            <Col md={9} className="text-center">
                {/* <Row>                  
                </Row> */}
                <Row>
                  <div className="text-center">
                    <div className="sm:text-[1rem]"><br/><br/></div>
                    <div className="sm:text-[2rem]">
                      <StarOutlined />
                    </div>
                    <h3 className="text-white">Hundreds of families served<br/></h3>
                    <h4 className="text-white">100 NPS score</h4>
                  </div>
                </Row>
            </Col>                
            <Col md={8}>
              {/* <Row>                  
              </Row> */}
              <Row>
              <div className="text-center">
                <div className="sm:text-[1rem]"><br/><br/></div>
                <div className="sm:text-[2rem]">
                  <TeamOutlined />
                </div>
                <h3 className="text-white">Employees, Brokers, Partners<br/></h3>
                {/* <h4 className="text-white">
                  Join us
                </h4> */}
                <Button
                className="p-1"
                type="primary"
                shape="round"
                style={{
                  background: "#8159f5",
                  borderColor: "#8159f5",
                  color: "white",
                }}
                icon={<MailOutlined />}
                onClick={() => {
                  navigate("/?modal=partner");
                }}
                ghost
                aria-controls="download-caregiver-plan"
                aria-expanded={1}
                size="small"
              >
                Join Us
              </Button>

              </div>
              </Row>
            </Col>                
            <Col md={2}/>
          </Row>
        </div>
      </section>
      <section className="bg-purple-50 left-0 w-full justify-center pb-20">
        <Row>
          <Col md={24}>
          <h1 className="pt-10 text-primary-purple text-center xs:text-[2rem]  md:text-[2.5rem] lg:text-[3rem] mt-5 pt-10 mb-0 pb-20">
          {/* <h2 className="text-primary-purple text-center xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] mt-0 pt-20 pb-20"> */}
          Case Studies
          {/* </h2> */}
          </h1>
          </Col>
        </Row>
        <Row>
          <Col md={2}/>
          <Col md={6}>
            <section className="overflow-hidden flex min-h-[550px]"
                              style={{
                                background: "#8159f555",
                                borderColor: "#8159f555",
                                // color: "white",
                                borderRadius: "5%"
                              }}            
              >
              <div  className="text-primary-purple m-10 text-[1rem]">
                <h3 className="text-left">
                  <strong>Employee</strong>
                  <br/><br/>
                </h3>
                <h4>
                  <strong><i>
                  Employee didn't know how to support her aging mom
                  who was sick and home bound.
                  </i></strong>
                  <br/><br/>
                </h4>
                <h4>
                  My CareLinq built a family care plan, incorporating her need and her mom's.
                  Our Care Concierges sourced service providers and coordinated services, saving the
                  daughter about 15 hours a week.  The employee was able to take less time away from 
                  work and focus on being a daughter instead of caregiver.
                </h4>
              </div>
            </section>
          </Col>
          <Col md={1}/>
          <Col md={6}>
            <section className="overflow-hidden flex min-h-[550px]"
                              style={{
                                background: "#8159f555",
                                borderColor: "#8159f555",
                                // color: "white",
                                borderRadius: "5%"
                              }}            
              >
              <div  className="text-primary-purple m-10 text-[1rem]">
                <h3 className="text-left">
                  <strong>Executive Leader</strong>
                  <br/><br/>
                </h3>
                <h4>
                  <strong><i>
                  Executive Leader from Fortune 500 Company needed
                  specialized care for a health condition.
                  </i></strong>
                  <br/><br/>
                </h4>
                <h4>
                  My CareLinq evaluated the situation and presented multiple creative alternatives
                  to ensure treatment and payment would be covered.  We equipped the executive with
                  talking points and key stakeholders to engage with.
                  <br/><br/>
                </h4>
              </div>
            </section>
          </Col>
          <Col md={1}/>
          <Col md={6}>
            <section className="overflow-hidden flex min-h-[550px]"
                              style={{
                                background: "#8159f555",
                                borderColor: "#8159f555",
                                // color: "white",
                                borderRadius: "5%"
                              }}            
              >
              <div  className="text-primary-purple m-10 text-[1rem]">
                <h3 className="text-left">
                  <strong>Entrepreneur</strong>
                  <br/><br/>
                </h3>
                <h4>
                  <strong><i>
                  Entrepreneur with aging parent didn't have the time to transfer
                  Medi-Cal benefits and find new providers and specialists.
                  </i></strong>
                  <br/><br/>
                </h4>
                <h4>
                  My CareLinq's knowledge and expertise ensured benefits were transferred,
                  providers notified, and appointments made.
                  <br/><br/>
                  My CareLinq saved the business owner approximately 12+ hours a week
                  and reduced his stress and anxiety.               
                </h4>
              </div>
            </section>
          </Col>
          <Col md={2}/>
        </Row>
      </section>
    </div>
  )
}

const Saying =() => {

  return (
    <div>
      <section className="bg-purple-50 left-0 w-full justify-center pb-5">
        <Row>
          <Col md={2}/>
          <Col md={22}>
          <h1 className="text-primary-purple text-left xs:text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] lg:text-[2.5rem] mt-5 pt-10 mb-0 pb-20">
          {/* <h2 className="text-primary-purple text-center xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] mt-0 pt-20 pb-20"> */}
          What Clients are Saying
          {/* </h2> */}
          </h1>
          </Col>
        </Row>
        {/* <Row>
          <Col md={2}/>
          <Col md={9}>
            <section className="text-primary-purple text-[1.25rem]">
              <h3>
                <i>"Excellent and timely followup on the most challenging
                questions about long distance caregiving."</i>
              </h3>
              <h4>
              <br></br>
                Betty, <i>Caregiver</i>
              </h4>
            </section>
          </Col>
          <Col md={2}/>
          <Col md={9}>
          <section className="text-primary-purple text-[1.25rem]">
              <h3>
                <i>"Two days after my wife passed away, I vividly remember being on the phone with various
                  mortuaries and funeral homes trying to finalize arrangements that we never planned for during
                  her illness.  I was hardly in any position to make any decision and was at the mercy of the
                  people I was talking with.  My CareLinq can make this process easier and they're there
                  to help every step of the way."</i>
              </h3>
              <h4>
              <br></br>
              Andrew, <i>My CareLinq Advocate</i>
              </h4>
            </section>
          </Col>
          <Col md={2}/>
        </Row> */}
      </section>
      <Jumbotron/>
    </div>
  )
}

const InTouch = () => {
  const navigate = useNavigate();
  return (
    <div
        className="
        pt-20
        pb-20
        sm:p-4        
        bg-purple-50
        left-0
        w-full
        min-h-[500px]"
    >
      <Row className="pt-20 pb-20">
        <Col md={1}/>
        <Col md={11}>
          <Row>
            <div className="pl-10 pr-10 min-h-[400px] w-full"
              style={{
                backgroundColor: "rgba(48, 34, 95, 0.75)",
                color: "white",
                borderTopLeftRadius: "5%",
                borderBottomLeftRadius: "5%",
              }}
            >
              <h1 className="text-white text-left xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] mt-0 pt-5">
                <br/>
                Get in Touch!
              </h1>
              <h4 className="text-white text-left xs:text-[0.4rem] sm:text-[0.5rem] md:text-[0.75rem] lg:text-[1.25rem] pb-5">
                Schedule time with us to discuss your<br/> employee benefit or special program<br/> needs.
                <br/><br/>
              </h4>
              <Button
                className="ml-20 p-2"
                type="primary"
                shape="round"
                style={{
                  // background: "#8159f5",
                  background: "white",
                  // borderColor: "#8159f5",
                  borderColor: "white",
                  color: "black",
                }}
                icon={<CalendarOutlined />}
                onClick={() => {
                  // ReactGA.event({
                  //   category: "home",
                  //   action: "button_click",
                  //   label: "download_checklist",
                  // });
                  // // navigate("/article/my-carelinq-caregiver-checklist");
                  // // setDownloadModalSetting((prevState) => ({
                  // //   ...prevState,
                  // //   isModalVisible: true,
                  // // }));
                  // setScheduleModalSetting((prevState) => ({
                  //   ...prevState,
                  //   isModalVisible: true,
                  // }));

                  // window.open("https://calendly.com/mycarelinq", "_blank");
                  navigate("/?modal=schedule");
                }}
                ghost
                aria-controls="download-caregiver-plan"
                aria-expanded={1}
                size="large"
              >
                Book Consultation
              </Button>
            </div>
          </Row>
        </Col>
        <Col md={11}>
          <section
            className="bg-cover bg-no-repeat bg-left overflow-hidden relative"
            style={{
              minHeight: "400px",
              height: "flex",
              // linearGradient: "rgba(255,255,255,1)",
              // borderRadius: "5%",
              borderTopRightRadius: "5%",
              borderBottomRightRadius: "5%",
              backgroundImage: `url(${HomeInTouch})`,
            }}
          />
        </Col>
        <Col md={1}/>
      </Row>
    </div>
  )
}

export {
  Hero,
  WhoAreCaregivers,
  Jumbotron,
  SectionBanner,
  Platform,
  PlatformFor,
  WhyMCL,
  Herov2,
  Benefits,
  CaseStudies,
  Saying,
  InTouch,
};
