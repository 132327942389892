import React, { useState, useCallback } from "react";
import ReactGA from "react-ga4";
import "./Product.css";
import { ToUContent } from "../../components/TermsOfUse";
import { SAContent } from "../../components/SA";
import useAuth from "../../hooks/useAuth";
import useMclAuth from "../../hooks/useMclAuth";
import { useNavigate } from "react-router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import  { Col, Row, Button, Modal, Checkbox, Collapse,
          Form, Select, Input,
        } from "antd";
import { Url } from "../../constants/Global";
import { mclOpen } from "../../api/Mcl";

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;


const isTest = process.env.REACT_APP_TEST === "yes";

export const STARTER_PRODUCT_ID = isTest ? "prod_REEzKuCb4WdCGH" : "prod_QEJ4UMg7wRED2o";

function ServiceDiv(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  // const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false);
  // const [isRegisterMode, setIsRegisterMode] = useState(false);

  const [tempEmail, setTempEmail] = useState("");
  const [touChecked, setTouChecked] = useState(false);
  const [saChecked, setSaChecked] = useState(false);
  const [subscribeNew, setSubscribeNew] = useState(true);
  const { auth, setAuth } = useAuth();
  const mclAuth = useMclAuth();
  const navigate = useNavigate();

  const buttonLabel = props.buttonLabel ? props.buttonLabel : "Get Started"
  const priceLabel = props.priceLabel ? props.priceLabel : (<>$<br/>MONTHLY</>)
  const buttonAction = props.buttonAction;

  // reCAPTCHA
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute reCAPTCHA not yet available");
      return;
    }

    const token = await executeRecaptcha("checkoutSession");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  // features style
  const listFeatures = props.product_features.map((feature, index) => {
    if (index === props.enhanced) {
      return (
        <li key={index} className="m-0 mb-2 p-0 flex features-none">
          <strong className="m-0 p-0 text-[#ca212c]">{feature}</strong>
        </li>
      );
    } else if (index < props.marker) {
      return (
        <li
          key={index}
          className={
            props.level === "diy"
              ? "m-0 mb-2 p-0 flex features-orange"
              : "m-0 mb-2 p-0 flex features-red"
          }
        >
          <strong className="m-0 p-0 xs:text-[.75rem] sm:text-[.75rem] md:text-[0.55rem] lg:text-[1rem] xl:text-[1rem]">
            {feature}
          </strong>
        </li>
      );
    } else if (props.indent && props.indent.includes(index)) {
      return (
        <li
          key={index}
          className={
            props.level === "diy"
              ? "ml-8 mb-2 p-0 flex features-orange"
              : "ml-8 mb-2 p-0 flex features-red"
          }
        >
          <p className="m-0 p-0 xs:text-[.75rem] sm:text-[.75rem] md:text-[0.55rem] lg:text-[1rem] xl:text-[1rem]">
            {feature}
          </p>
        </li>
      );
    } else {
      return (
        <li
          key={index}
          className={
            props.level === "diy"
              ? "m-0 mb-2 p-0 flex features-orange"
              : "m-0 mb-2 p-0 flex features-red"
          }
        >
          <p className="m-0 p-0 xs:text-[.75rem] sm:text-[.75rem] md:text-[0.55rem] lg:text-[1rem] xl:text-[1rem]">
            {feature}
          </p>
        </li>
      );
    }
  });

  const productButtonClick = (event) => {
    console.log('got here!!!', props.buttonAction);
  }

  const startButtonClickv0 = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'pricing',
      action: 'click_button',
      label: 'start_' + props.inSub
    });
    setIsModalVisible(true);

    if (!tempEmail && !auth.accessToken) {
      setIsEmailModalVisible(true);
      ReactGA.event({
        category: 'pricing',
        action: 'open_modal',
        label: 'email_modal',
        nonInteraction: true,
      });
    } else if (auth.accessToken) {
      mclAuth.get(Url.CIRCLES.concat("/checkPrimary")).then((res) => {
        if (res.data === "yes") {
          if (props.inSub != "None" && props.inSub != "FREE") {
            // upgrading
            setSubscribeNew(false);
          } else {
            // first time subscription
            setSubscribeNew(true);
          }
          setIsModalVisible(true);
          ReactGA.event({
            category: 'pricing',
            action: 'open_modal',
            label: 'confirm_modal',
            nonInteraction: true,
          });
        } else {
          alert("Only primary caregiver can make payment.");
        }
      });
    }
  };

  const startButtonClick = (event, action) => {
    event.preventDefault();
    ReactGA.event({
      category: 'pricing',
      action: 'click_button',
      label: 'start_' + props.inSub
    });

    // if (action === 'register') {
    //   setIsRegisterMode(true);
    // }
    setIsModalVisible(true);

    // if (!tempEmail && !auth.accessToken) {
    //   setIsEmailModalVisible(true);
    //   ReactGA.event({
    //     category: 'pricing',
    //     action: 'open_modal',
    //     label: 'email_modal',
    //     nonInteraction: true,
    //   });
    // } else if (auth.accessToken) {
    //   mclAuth.get(Url.CIRCLES.concat("/checkPrimary")).then((res) => {
    //     if (res.data === "yes") {
    //       if (props.inSub != "None" && props.inSub != "FREE") {
    //         // upgrading
    //         setSubscribeNew(false);
    //       } else {
    //         // first time subscription
    //         setSubscribeNew(true);
    //       }
    //       setIsModalVisible(true);
    //       ReactGA.event({
    //         category: 'pricing',
    //         action: 'open_modal',
    //         label: 'confirm_modal',
    //         nonInteraction: true,
    //       });
    //     } else {
    //       alert("Only primary caregiver can make payment.");
    //     }
    //   });
    // }
  };

  const handleModalSubmitv0 = (event) => {
    event.preventDefault();
    setIsModalVisible(false);
    if (!subscribeNew) {
      ReactGA.event({
        category: 'pricing',
        action: 'submit_subscribe',
        label: 'upgrade',
        nonInteraction: true,
      });
      mclAuth
        .get(Url.PAYMENT.concat("/upgradeStripeSubs/" + props.product_ID))
        .then((res) => {
          alert(res.data);
          mclAuth
            .get(Url.PAYMENT.concat("/currentPlan"))
            .then((res) => {
              if (res.data) {
                props.setCurrentPlan(res.data);
                mclAuth
                  .get(Url.PAYMENT.concat("/subExpiration"))
                  .then((res) => {
                    if (res) {
                      props.setExpiration(
                        res.data === 0
                          ? ""
                          : ", expiration:" +
                          new Intl.DateTimeFormat("en-US").format(
                            res.data * 1000
                          )
                      );
                    }
                  });
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      const recaptchaFirst = async () => {
        ReactGA.event({
          category: 'pricing',
          action: 'submit_subscribe',
          label: 'new_subscribe',
          nonInteraction: true,
        });
        let recaptchaToken = await handleReCaptchaVerify();

        if (recaptchaToken) {
          if (auth.accessToken) {
            // Get the recaptcha token, submit it with the data to the backend
            mclAuth.get(Url.PAYMENT.concat("/stripeId")).then((res) => {
              // if stripe id exists
              if (res.data) {
                mclOpen
                  .post(
                    Url.PAYMENT.concat("/createStripeSubs"),
                    {
                      customerID: res.data,
                      productID: props.product_ID,
                      priceID: props.product_priceID,
                      price: props.product_price,
                    },
                    {
                      params: {
                        token: recaptchaToken,
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("tempEmail");
                    localStorage.setItem("accessToken", auth.accessToken);
                    window.location.href = res.data;
                  });
              } else {
                mclAuth.get(Url.PAYMENT.concat("/username")).then((res) => {
                  mclOpen
                    .post(
                      Url.PAYMENT.concat("/createStripeSubs"),
                      {
                        firstName: res.data[0],
                        lastName: res.data[1],
                        email: auth.email,
                        productID: props.product_ID,
                        priceID: props.product_priceID,
                        price: props.product_price,
                      },
                      {
                        params: {
                          token: recaptchaToken,
                        },
                      }
                    )
                    .then((res) => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("tempEmail");
                      localStorage.setItem("accessToken", auth.accessToken);
                      window.location.href = res.data;
                    });
                });
              }
            });
          } else {
            // for non login users
            ReactGA.event({
              category: 'pricing',
              action: 'submit_subscribe',
              label: 'for_non_login_users',
              nonInteraction: true,
            });
            mclOpen
              .post(
                Url.PAYMENT.concat("/createStripeSubs"),
                {
                  email: tempEmail,
                  productID: props.product_ID,
                  priceID: props.product_priceID,
                  price: props.product_price,
                },
                {
                  params: {
                    token: recaptchaToken,
                  },
                }
              )
              .then((res) => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("tempEmail");
                localStorage.setItem("tempEmail", tempEmail);
                window.location.href = res.data;
              });
          }
        } else {
          ReactGA.event({
            category: 'pricing',
            action: 'submit_subscribe',
            label: 'subscribe_failed_missing_recaptcha',
            nonInteraction: true,
          });
          alert("Missing recaptcha token.");
        }
      };

      recaptchaFirst();
    }
  };

  const handleModalSubmit = (event) => {
    event.preventDefault();
    setIsModalVisible(false);
    // if (!subscribeNew) {
    //   ReactGA.event({
    //     category: 'pricing',
    //     action: 'submit_subscribe',
    //     label: 'upgrade',
    //     nonInteraction: true,
    //   });
    //   mclAuth
    //     .get(Url.PAYMENT.concat("/upgradeStripeSubs/" + props.product_ID))
    //     .then((res) => {
    //       alert(res.data);
    //       mclAuth
    //         .get(Url.PAYMENT.concat("/currentPlan"))
    //         .then((res) => {
    //           if (res.data) {
    //             props.setCurrentPlan(res.data);
    //             mclAuth
    //               .get(Url.PAYMENT.concat("/subExpiration"))
    //               .then((res) => {
    //                 if (res) {
    //                   props.setExpiration(
    //                     res.data === 0
    //                       ? ""
    //                       : ", expiration:" +
    //                       new Intl.DateTimeFormat("en-US").format(
    //                         res.data * 1000
    //                       )
    //                   );
    //                 }
    //               });
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error.message);
    //         });
    //     })
    //     .catch((error) => {
    //       console.log(error.message);
    //     });
    // } else {
      const recaptchaFirst = async () => {
        ReactGA.event({
          category: 'pricing',
          action: 'submit_subscribe',
          label: 'new_subscribe',
          nonInteraction: true,
        });
        let recaptchaToken = await handleReCaptchaVerify();

        if (recaptchaToken) {
          // if (auth.accessToken && false) {
          //   // Get the recaptcha token, submit it with the data to the backend
          //   mclAuth.get(Url.PAYMENT.concat("/stripeId")).then((res) => {
          //     // if stripe id exists
          //     if (res.data) {
          //       mclOpen
          //         .post(
          //           Url.PAYMENT.concat("/createStripeSubs"),
          //           {
          //             customerID: res.data,
          //             productID: props.product_ID,
          //             priceID: props.product_priceID,
          //             price: props.product_price,
          //           },
          //           {
          //             params: {
          //               token: recaptchaToken,
          //             },
          //           }
          //         )
          //         .then((res) => {
          //           localStorage.removeItem("accessToken");
          //           localStorage.removeItem("tempEmail");
          //           localStorage.setItem("accessToken", auth.accessToken);
          //           window.location.href = res.data;
          //         });
          //     } else {
          //       mclAuth.get(Url.PAYMENT.concat("/username")).then((res) => {
          //         mclOpen
          //           .post(
          //             Url.PAYMENT.concat("/createStripeSubs"),
          //             {
          //               firstName: res.data[0],
          //               lastName: res.data[1],
          //               email: auth.email,
          //               productID: props.product_ID,
          //               priceID: props.product_priceID,
          //               price: props.product_price,
          //             },
          //             {
          //               params: {
          //                 token: recaptchaToken,
          //               },
          //             }
          //           )
          //           .then((res) => {
          //             localStorage.removeItem("accessToken");
          //             localStorage.removeItem("tempEmail");
          //             localStorage.setItem("accessToken", auth.accessToken);
          //             window.location.href = res.data;
          //           });
          //       });
          //     }
          //   });
          // } else {
            // for non login users
            // ReactGA.event({
            //   category: 'pricing',
            //   action: 'submit_subscribe',
            //   label: 'for_non_login_users',
            //   nonInteraction: true,
            // });
            ReactGA.event("begin_checkout", {
              currency: "USD",
              value: props.product_price,
              items: [
                {
                  item_id: props.product_ID,
                  item_name: props.product_name
                }
              ]
            });

            mclOpen
              .post(
                // Url.PAYMENT.concat("/createStripeSubs"),
                Url.PAYMENT.concat("/buynowStripePayment"),
                {
                  // email: tempEmail,
                  productID: props.product_ID,
                  priceID: props.product_priceID,
                  price: props.product_price,
                },
                {
                  params: {
                    token: recaptchaToken,
                  },
                }
              )
              .then((res) => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("tempEmail");
                localStorage.setItem("tempEmail", tempEmail);
                window.location.href = res.data;
              });
          // }
        } else {
          ReactGA.event({
            category: 'pricing',
            action: 'submit_subscribe',
            label: 'subscribe_failed_missing_recaptcha',
            nonInteraction: true,
          });
          alert("Missing recaptcha token.");
        }
      };

      recaptchaFirst();
    // }
  };

  return (
    <div className="m-5 flex flex-col h-full bg-purple-50 rounded-xl">
      <Modal
        // title={
        //   "You are about to " +
        //   (subscribeNew ? "subscribe to a new plan" : "change plan") +
        //   ". Please read through the following contents."
        // }
        title={
          "You are about to avail of a new plan" +
          ". Please read through the following contents."
        }
        visible={isModalVisible}
        centered
        onCancel={() => {
          setIsModalVisible(false);
          setTempEmail("");
        }}
        className="checkoutModal"
        footer={(null, null)}
      >
        <Collapse
          accordion
          defaultActiveKey={["1"]}
          className="checkoutCollapse"
        >
          <Panel header="Terms of Use" key="1" className="max-h-fit">
            <ToUContent className="mt-5" />
          </Panel>
          {/* <Panel header="SUBSCRIPTION AGREEMENT" key="2" className="max-h-fit">
            <SAContent className="mt-5" />
          </Panel> */}
        </Collapse>
        <Row className="my-5 justify-center">
          <Checkbox
            onChange={() => {
              setTouChecked(!touChecked);
            }}
          >
            I acknowledge the <strong>term of use</strong>
          </Checkbox>
          {/* <Checkbox
            onChange={() => {
              setSaChecked(!saChecked);
            }}
          >
            I acknowledge the <strong>subscription agreement</strong>
          </Checkbox> */}
        </Row>
        <Row className="mb-5 justify-center">
          <form
            action={Url.BASE.concat(Url.PAYMENT.concat("/createStripeSubs"))}
            method="POST"
            onSubmit={handleModalSubmit}
          >
            <input type="hidden" name="productID" value={props.product_ID} />
            <input type="hidden" name="priceID" value={props.product_priceID} />
            <input type="hidden" name="price" value={props.product_price} />
            {/* <Button disabled={!touChecked || !saChecked} htmlType="submit"> */}
            <Button disabled={!touChecked} htmlType="submit" 
                className={"mx-auto mb-3 border-0 rounded-lg bg-[#44cff2] text-white text-lg h-fit"}
            >
              continue
            </Button>
          </form>
        </Row>
      </Modal>
      <Modal
        title={"You have not logged in."}
        visible={isEmailModalVisible}
        centered
        onCancel={() => {
          ReactGA.event({
            category: 'pricing',
            action: 'close_modal',
            label: 'email_modal'
          });
          setIsEmailModalVisible(false);
          setTempEmail("");
        }}
        footer={(null, null)}
      >
        <p>
          Please provide your <strong>email address</strong> below then you can
          continue!
        </p>
        <p>
          or simply{" "}
          <Button
            className={
              "mx-auto mb-3 border-0 rounded-lg bg-[#44cff2] text-white h-fit"
            }
            onClick={() => {
              ReactGA.event({
                category: 'pricing',
                action: 'click_button',
                label: 'login_email_modal'
              });
              navigate("/site/login")
            }}
          >
            log in
          </Button>{" "}
          if you have an account.
        </p>
        <input
          className="mx-4 my-0 w-1/2 rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0"
          value={tempEmail}
          onChange={(event) => {
            setTempEmail(event.target.value);
          }}
        />
        <Button
          disabled={!tempEmail}
          onClick={() => {
            ReactGA.event({
              category: 'pricing',
              action: 'click_button',
              label: 'continue_email_modal'
            });
            setIsEmailModalVisible(false);
            mclOpen
              .get(Url.PAYMENT.concat("/userFromEmail/", tempEmail))
              .then((result) => {
                if (result.data) {
                  alert(
                    "Looks like you already have an account! Please login to continue."
                  );
                  navigate("/site/login");
                } else {
                  setIsModalVisible(true);
                }
              });
          }}
        >
          continue
        </Button>
      </Modal>
      <Row className="m-0 p-0">
        <Col xs={24} className="m-0 mt-3 p-0 flex">
          <strong className="mx-auto mt-2 xl:text-[1.75rem] lg:text-[1.25rem] md:text-[0.75rem] sm:text-[1.25rem] xs:text-[1.25rem] text-center">
            {props.product_name}
          </strong>
        </Col>
      </Row>
      {/* <Row
        className="text-[#e0551b] p-0 mt-10 xs:h-[5.5rem] sm:h-[5.5rem] md:h-[5.5rem] lg:h-[5.5rem] xl:h-[5.5rem]"
      >
        <Col xs={ props.product_priceLabel ? 24 : 12} className="p-0">
          <p className={props.product_priceLabel ? "my-3 text-center xs:text-[2] sm:text-[2.5 rem] md:text-[1.5rem] lg:text-[2.5rem] xl:text-[3.5rem]" 
            : "my-3 text-right xs:text-[3] sm:text-[3rem] md:text-[2rem] lg:text-[3rem] xl:text-[4rem]"}>
            {props.product_priceLabel ? props.product_priceLabel : props.product_price}
          </p>
        </Col>
        <Col xs={12} className="p-0">
          <p className="my-3 xs:text-[1.25rem] sm:text-[1.25rem] md:text-[1rem] lg:text-[1.5rem] xl:text-[1.5rem]">
            {props.product_priceLabel ? "" : <>$</>}
          </p>
        </Col>
      </Row> 
      */}

      {props.product_priceLabel ? 
          <Row
            className="text-[#e0551b] p-0 mt-10 xs:h-[4.5rem] sm:h-[4.5rem] md:h-[4.5rem] lg:h-[4.5rem] xl:h-[4.5rem]"
          >
            <Col xs={24} className="p-0">
            <p className="my-3 text-center xs:text-[2] sm:text-[2.5 rem] md:text-[1.5rem] lg:text-[2.5rem] xl:text-[3.5rem]">
              {props.product_priceLabel}
            </p>
            </Col> 
          </Row>
          :
          <Row
            className="text-[#e0551b] p-0 mt-10 xs:h-[4.5rem] sm:h-[4.5rem] md:h-[4.5rem] lg:h-[4.5rem] xl:h-[4.5rem]"
          >
            <Col xs={8} className="p-0">
              <p className="my-3 text-right xs:text-[1.25rem] sm:text-[1.25rem] md:text-[1rem] lg:text-[1.5rem] xl:text-[1.5rem]">
                $
              </p>
            </Col>          
            <Col xs={16} className="p-0">
              <p className="xs:text-[3] sm:text-[3rem] md:text-[2rem] lg:text-[3rem] xl:text-[4rem]">
                {props.product_price}
              </p>
            </Col>
          </Row>
      }

      <Row className="m-0 p-0 xs:h-[4rem] sm:h-[4rem] md:h-[4rem] lg:h-[4rem] xl:h-[4rem]">
        <Col xs={24}>
          <p className="px-5 m-0 text-center xs:text-[.75rem] sm:text-[.75rem] md:text-[1rem] lg:text-[1.25rem] xl:text-[1.5rem]">
            {/* {props.product_des} */}
          </p>
        </Col>
      </Row>
      <Row className="my-2 p-0 h-[7rem]">
        <Button
          className={
            "mx-auto mb-3 border-0 rounded-lg bg-[#44cff2] text-white text-xl h-fit w-3/4"
          }
          // onClick={productButtonClick}
          // onClick={(event) => {buttonAction === "subscribe" ? navigate("/survey") : 
            onClick={(event) => {buttonAction === "register" ? navigate(`/register/${props.product_ID}`) : 
              buttonAction === "chat" ? window.open("https://calendly.com/mycarelinq", "_blank") :startButtonClick(event, buttonAction) }}
        >
          {buttonLabel}
        </Button> 
      </Row>
      <ul className="mx-8 pl-4 list-none grow text-lg">{listFeatures}</ul>
    </div>
  );
}

class ServiceProductv3 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // names: ["FREE SIGN-UP", "STARTER", "PREMIUM", "ALL ACCESS" /*"GOLD"*/],
      // names: ["STARTER", "PREMIUM", "ALL ACCESS", "ENTERPRISE"],
      names: ["Family Care Plan", 
              "Custom Family Care Plan", 
              "On-Site Caregiver Support", 

              "ENTERPRISE"],
      action: ["subscribe","subscribe","subscribe","chat"],
      productIDs: isTest
        // start test
        ? 
        // props?.b2b 
        // ? [
        //   // "prod_LpXJrSJrXWQRhm",
        //   "prod_MEBLgKzU9gNfk9",
        //   "prod_MEBLgKzU9gNfk9",
        //   "prod_LqCkJmOg0adAL9",

        //   "prod_LqCkG09S50YCDU",
        // ]
        // : 
        [
          // "prod_LpXJrSJrXWQRhm",
          /* "prod_REEzKuCb4WdCGH",*/ STARTER_PRODUCT_ID,
          "prod_REF0shLP3f1uhd",
          "prod_REF1unfym6wY9W",

          // "prod_LtDQqJplbT7t7z",
        ]
        // end test

        // : props?.b2b 
        // ? [
        //   // "prod_M484rJD13IpFWf",
        //   "prod_PE7D3MIAzzjNOq",
        //   "prod_PE7GEtFQfsqUvT",
        //   "prod_PE7KMf361FV8Wc",

        //   "prod_PE7KMf361FV8Wc",
        // ]
        :
        [
          /*"prod_QEJ4UMg7wRED2o",*/ STARTER_PRODUCT_ID,
          "prod_RHULMPeK02zG1P",
          "prod_QEJ8DruGQa62YM",

          // "prod_LpYSCWsU8s7Tv2",
          // "",
        ]
        ,
      descriptions: [
        "Online Family Care Plan",
        // "On-Site Family Caregiver Support",
        "",
        // "Best for more intensive support and assistance",
        "",

        "For Employers, Healthcare providers, Government Agencies"
      ],
      features: [ 
        [
          "Comprehensive Family Care Plan template.  Unlimited access",
          "Collaborate with family, friends, and other caregivers involved",
          "Option to purchase Care Coordination services",
          "One-time purchase",
        ],
        [
          "60-minute consultation",
          "Custom family care plan",
          "Implementation plan",
          "Create and manage your care plan",
          "Recommend products and providers"
        ],
        [
          "Dedicated Care Concierge On-site",
          "Comprehensive assessment",
          "Develop care plan for family caregivers and patient",
          "Set up, implement, manage plan, communications, and service providers",
          "Chat, email, video conferencing"
        ],
        [
          "Address and solve the most pressing business challenges driven by elder care and family caregiver needs",
          "Develop strategy and actions to ensure optimal outcomes and ROI",
          "Custom benefit solutions",
          "Custom care concierge programs",
          "Custom technology solutions",
          "Speaking engagements",
          "Workshops",
          "Analytics"
        ],
      ],
      priceIDs: Array(5).fill(""),
      // prices: props?.b2b ? isTest ? [0, 0, 0, 0, 0]  : [0, 1400, 5900, 19900, 100000] : [0, 1400, 5900, 19900, 100000],
      // prices: props?.b2b ? [0, 1000, 4200, 14000, 100000] : [0, 1400, 5900, 19900, 100000],
      prices: props?.b2b ? [2500, 15000, 300000, 14000, 100000] 
                         : [2500, 15000, 300000, 14000, 100000],
    };

    // console.log('!@$*&*^^% props auth:', props?.b2b);
    // console.log('!@$*&*^^% props productIDs:', this.state?.productIDs);

    var count = this.state.productIDs.length;
    const pricesIDCopy = this.state.priceIDs.slice();
    const pricesCopy = this.state.priceIDs.slice();
    this.state.productIDs.map((productID, index) => {
      mclOpen
        .get(Url.PAYMENT.concat("/product/", productID))
        .then((result) => {
          pricesIDCopy[index] = result.data.default_price;
        })
        .then(() => {
          if (pricesIDCopy[index] === null) {
            pricesCopy[index] = 0;
            count -= 1;

            if (count === 0) {
              this.setState({
                priceIDs: pricesIDCopy,
              });
            }
          } else {
            mclOpen
              .get(Url.PAYMENT.concat("/price/", pricesIDCopy[index]))
              .then((result) => {
                pricesCopy[index] = result.data.unit_amount;
                count -= 1;
              })
              .then(() => {
                if (count === 0) {
                  this.setState({
                    // priceIDs: pricesIDCopy,
                    prices: pricesCopy,
                  });
                }
              });
          }
        });
    });
  }

  render() {
    return (
      // <div className="lg:container lg:mx-auto w-full p-0 bg-[url('assets/images/pricing/products-bg.png')] bg-center bg-no-repeat bg-cover overflow-hidden">
      // <div className="lg:container lg:mx-auto w-full p-0 bg-[#d4f4fc] bg-center bg-no-repeat bg-cover overflow-hidden">
      <div className="lg:container lg:mx-auto w-full p-0 ">
      <Row className="p-0 m-0">
          <Col
            // xs={24}
            // md={12}
            xs={24}
            md={24}
            className="p-0 m-0 flex flex-col"
          >
            <Row className="p-0 m-0">
              <Col
                xs={24}
                className="p-0 m-0 h-10 text-center flex flex-col justify-center"
              >
                <p className="p-1 m-0 xxl:text-[1.75rem] xl:text-[1.5rem] lg:text-[1.25rem] md:text-[1rem] sm:text-[1.5rem]">
                  {/* For Family Caregivers and Patients */}
                </p>
              </Col>
            </Row>
            <Row className="p-0 m-0 grow">
            <Col xs={8} className="p-0">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[0]}
                  product_name={this.state.names[0]}
                  product_priceID={this.state.priceIDs[0]}
                  product_price={this.state.prices[0] / 100}
                  product_des={this.state.descriptions[0]}
                  product_features={this.state.features[0]}
                  marker={1}
                  level={"diy"}
                  buttonLabel="Buy Now"
                  buttonAction="register"
                />
              </Col>
              <Col xs={8} className="p-0">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[1]}
                  product_name={this.state.names[1]}
                  product_priceID={this.state.priceIDs[1]}
                  product_price={this.state.prices[1] / 100}
                  product_des={this.state.descriptions[1]}
                  product_features={this.state.features[1]}
                  marker={2}
                  level={"diy"}
                  buttonLabel="Buy Now"
                  buttonAction="checkout"
                />
              </Col>
              {/* <Col xs={12} className="p-0 vertical-divider"> */}
              <Col xs={8} className="p-0">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[2]}
                  product_name={this.state.names[2]}
                  product_priceID={this.state.priceIDs[2]}
                  product_price={this.state.prices[2] / 100}
                  product_des={this.state.descriptions[2]}
                  product_features={this.state.features[2]}
                  marker={1}
                  level={"diy"}
                  buttonLabel="Buy Now"
                  buttonAction="checkout"
                />
              </Col>

            </Row>
          </Col>
          {/* <Col xs={24} md={12} className="p-0 m-0"> */}
          {/* <Col xs={8} md={8} className="p-0 m-0">
            <Row className="p-0 m-0">
              <Col
                xs={24}
                className="h-28 p-3 text-center bg-[#ea916b] flex flex-col justify-center"
              >
                <p className="p-1 m-0 xxl:text-[1.75rem] xl:text-[1.5rem] lg:text-[1.25rem] md:text-[1rem] sm:text-[1.5rem] text-white">
                  For Enterprise
                </p>
              </Col>
            </Row>
            <Row className="p-0 m-0"> */}
              {/* <Col xs={12} className="p-0 vertical-divider">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[2]}
                  product_name={this.state.names[2]}
                  product_priceID={this.state.priceIDs[2]}
                  product_price={this.state.prices[2] / 100}
                  product_des={this.state.descriptions[2]}
                  product_features={this.state.features[2]}
                  marker={2}
                  level={"concierge"}
                />
              </Col> */}



              {/* <Col xs={24} className="p-0">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[3]}
                  product_name={this.state.names[3]}
                  product_priceID={this.state.priceIDs[3]}
                  product_price={this.state.prices[3] / 100}
                  product_des={this.state.descriptions[3]}
                  product_features={this.state.features[3]}
                  marker={1}
                  // indent={[3, 4, 5, 6, 7, 8, 9, 10, 11]}
                  level={"diy"}
                  buttonLabel="Contact Sales"
                  buttonAction="chat"
                  product_priceLabel="Let's Talk"
                />
              </Col> */}

              {/*<Col xs={12} className="p-0">
                                <ServiceDiv
                                inSub={this.props.inSub}
                                setCurrentPlan={this.props.setCurrentPlan}
                                setExpiration={this.props.setExpiration}
                                product_ID={this.state.productIDs[3]}
                                product_name={this.state.names[3]}
                                product_priceID={this.state.priceIDs[3]}
                                product_price={this.state.prices[3] / 100}
                                product_des={this.state.descriptions[3]}
                                product_features={this.state.features[3]}
                                marker={5}
                                enhanced={5}
                                level={"concierge"}
                                />
                            </Col>*/}
            {/* </Row>
          </Col> */}
        </Row>
      </div>
    );
  }
}

export default ServiceProductv3;
