import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Col, Row, Button, Upload, Spin  } from 'antd';
import { UploadOutlined, FileOutlined, FileProtectOutlined, FileTextOutlined, ReloadOutlined, PlayCircleOutlined, FastForwardOutlined, StopOutlined } from '@ant-design/icons';

import { mclOpen } from "../../api/Mcl";
import { Url } from "../../constants/Global";
import { openNotification } from "../../components/Various";


import { Navbar } from "../../components/Navbar";
import { PrivacyPolicy, PrivacyPolicyModal } from "../../components/PrivacyPolicy"
import Footer from "../../components/Footer"
import { GeneratePlanModal, ViewTextModal, PasswordModal } from "../../components/PlanComponents"


//import { Link } from "react-router-dom";

export const DemoPlan = () => {
  const navigate = useNavigate();
  const [isLoadList, setIsLoadList] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [isTranscribe, setIsTranscribe] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [authkey, setAuthkey] = useState(null);
  const [changekey, setChangekey] = useState(null);

  const llms = [
    {name: 'openai', desc: 'Open AI', models: ["gpt-4o", "gpt4", "gpt4-turbo", "gpt-4-turbo-preview", "gpt-4-32k", "gpt-4-32k-0314", "gpt-4-32k-v0314", "gpt-4-vision-preview"]},
    {name: 'mistral', desc: 'Mistral', models: ["mistral-large-latest", "open-mistral-nemo"]},
    {name: 'anthropic', desc: 'Anthropic', models: ["claude-3-opus-20240229", "claude-3-sonnet-20240229","claude-3-haiku-20240307"]},
    {name: 'ollama', desc: 'OLlama', models: ["llama3", "gemma2", "mistral-nemo", "mistral-large", "qwen2", "phi3"]},
  ]

  const prompts = [
    {name: "custom", modify: true, value:"Create a family care plan....."},
    {name: "default", modify: false, value: "\nYou are a family care coordinator/personal concierge who is interviewing a family caregiver taking care of a family member who is a patient. From an attached interview transcript, you need to create a comprehensive care plan that includes caregiving tasks and prioritizes them in the order of importance based on assessments of the family caregiver's role and relationship to the person receiving care and of the patient's physical and emotional state, including their diagnosis, comorbidities, age, activity level, loneliness level, eating and drinking habits, and recent health changes. When listing the plan's tasks, consider the patient's health care coverage, general income level, physical location, and cultural or religious factors that may impact medical care, social medicine, and community support."},
    {name: "variant1", modify: false, value:"Create a family care plan with respect to the caregiver's needs and objectives, including filling gaps in the detailed knowledge of the caregiver's and patient's information collected in the attached transcript of the initial conversation between care coordinator and caregiver."},
  ]

  const initialModaViewData = {
    modalShow: false,
    modalTitle: "Transcript",
    content: "",
  };

  const [modalViewData, setModalViewData] = useState(initialModaViewData)

  const initialModalGenData = {
    modalTitle: "",
    modalShow: false,
    audioName: "",
    transcriptName: "",
    form: undefined,
    llms: llms,
    prompts: prompts,
    plan: ""
  }
 
  const [modalGenData, setModalGenData] = useState(initialModalGenData)

    // const handleOk = () => {
    //     console.log("Ok");
    //     setModalVisible(false);
    // }
    // const handleCancel = () => {
    //     console.log("Cancel");
    //     setModalVisible(false);
    // }

  // const openTextModal = (modalTitle = 'Transcript View', content = '') => {
  //   setModalViewData((prevState) => ({...prevState, modalShow: true, content: content})); 
  // }

  const validatePassword = () => {
    // let val = await calculateHash("wecare!");
    // console.log(val);
    // setIsAuthorized(true);
    setAuthkey(changekey);
  }

  const closeTextModal = () => {
    setModalViewData((prevState) => ({...prevState, modalTitle: "", modalShow: false, content: ""}));
  }

  const viewTextFile = async (title, fileName) => {
    console.log(title);
    console.log(Url.PLAN.concat('/files/'+fileName));
    mclOpen.get(Url.PLAN.concat('/files/'+fileName)).
      then((res) => {
        console.log(res.data);
        setModalViewData((prevState) => ({...prevState, modalTitle: title, modalShow: true, content: res.data})); 
      }).catch((err) => {
        console.log(err);
      });
  }

  const showGenModal = (title, audioName, transcriptName) => {
    // setModalGenData((prevState) => ({...prevState, modalTitle: title, modalShow: true, audio, transcript}));
    setModalGenData((prevState) => ({...prevState, modalTitle: title, modalShow: true, 
                                    audioName: audioName, transcriptName: transcriptName}));
  }

  const closeGenModal = () => {
    setModalGenData((prevState) => ({...prevState, modalTitle: "", modalShow: false, plan: ""}));
  };

  const getFiles = async () => {
    mclOpen.get(Url.PLAN.concat('/files'))
      .then((res) => {
          // setNotFoundError(false)
          // console.log(JSON.stringify(res.data));
          setFileList(res.data);
          // data.content = convertContentToString(data.content);
          // setAdjacent(data.prev, data.next);
          // setArticleContent(_.omit(data, ["prev", "next"]));
      })
      .catch((error) => {
          console.log(error)
          if (error.response.status == 404) {
              // setNotFoundError(true)
          }
      });
  }

  const getTranscribeStatus = () => {
    mclOpen.get(Url.PLAN.concat('/compute/status'))
      .then((res) => {
          // setNotFoundError(false)
          console.log('transcribe status call: ', JSON.stringify(res.data));
          const status = res?.data?.status;
          setIsTranscribe(status === 'running' ? true : false);
      })
      .catch((error) => {
          console.log('transcribe status error:', error.message)
          if (error.response.status == 404) {
              // setNotFoundError(true)
          }
      });
  }

  const startstopTranscribe = () => {
    const cmd = isTranscribe ? 'stop' : 'start';
    try {
      mclOpen.get(Url.PLAN.concat(`/compute/${cmd}`))
      .then((res) => {
          // setNotFoundError(false)
          console.log(`!! transcribe ${cmd} call: `, JSON.stringify(res.data));
          getTranscribeStatus();
      })
      .catch((error) => {
          console.log(`transcribe ${cmd} error:`, error.message)
          if (error.response.status == 404) {
              // setNotFoundError(true)
          }
      });  
    } catch (error) {
    }
  }

  const transcribeAudio = (fileAudio) => {
    try {
      mclOpen.get(Url.PLAN.concat(`/transcribe/${fileAudio}`))
      .then((res) => {
          // setNotFoundError(false)
          console.log(`transcribe audio ${fileAudio} success: `, JSON.stringify(res.data));
          openNotification({
            message: `${JSON.stringify(res.data)}`,
            status: "200",
            title: `${fileAudio} transcription process started`
          });
          })
      .catch((error) => {
          console.log(`transcribe audio ${fileAudio} error:`, error.message)
          openNotification({
            message: "Sorry the audio could not be transcribed",
            status: error.status ? error.status : "500",
            title: error.response.data
          });
      });  
    } catch (error) {
      openNotification({
        message: "Sorry the audio could not be transcribed",
        status: error.status ? error.status : "500",
        title: error.response.data
      });
    }
  }



  useEffect(() => {
    const compareHash = async (str) => {
      const encoder = new TextEncoder();
      const data = encoder.encode(str);
      const hashBuffer = await crypto.subtle.digest("SHA-256", data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
      console.log(str, hashHex);
      if (hashHex === "5d601c9f3f0339f619b6690cf01635231a8434f780e1e430096b91b4e3df5aab") {
        setIsAuthorized(true);
      } 
      // return hashHex;
    };

    if (!isAuthorized) {
      compareHash(changekey);
    }
  
    }, [authkey])

  useEffect(() => {
    if (isLoadList) {
      getFiles();
      setIsLoadList(false);
    }
  }, [isLoadList])


  useEffect(() => {
    getTranscribeStatus();

    const interval = setInterval(() => {
      getTranscribeStatus(); 
        console.log("transcribe poll");
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const PlanView = () => {
    return (
        <main>
            <Navbar signin={true} navigate={navigate} />
            <ViewTextModal 
              modalTitle={modalViewData && modalViewData.modalTitle}
              modalShow={modalViewData && modalViewData.modalShow}
              handleOk={closeTextModal}
              handleCancel={closeTextModal}
              value={modalViewData.content}
            />
            <GeneratePlanModal
              modalTitle={modalGenData && modalGenData.modalTitle}
              modalShow={modalGenData && modalGenData.modalShow}
              // onChange={(e) => {
              //     setModalTranscriptData((prevState) => ({
              //         ...prevState,
              //         description: e.target.value,
              //     }));
              // }}
              handleOk={closeGenModal}
              handleCancel={closeGenModal}
              llms = {modalGenData && modalGenData.llms}
              prompts = {modalGenData && modalGenData.prompts}
              audioName= {modalGenData && modalGenData.audioName}
              transcriptName={modalGenData && modalGenData.transcriptName}
            />
            <section
                  className="mt-20 ml-10 mr-10 pt-3 pr-10 pb-1 pl-10 flex text-center items-center justify-center xs:text-[0.6rem] sm:text-[0.7rem] md:text-[0.85rem] lg:text-[1.35rem]"
                  style={{
                    backgroundColor: "rgba(48, 34, 95, 0.75)",
                    color: "white",
                  }}
            
            >
              <Row>
                <Col md={24}>
                    <p>Recordings, Transcripts and Care Plans</p>
                </Col>
              </Row>
            </section>
            <section className="ml-5 mt-10">
              <Row>
                <Col md={15}/>
                <Col md={2}>
                  <Button 
                    type="primary" 
                    shape="round"
                    icon={<ReloadOutlined/>}
                    onClick={() => {
                      setIsLoadList(true);
                    }}
                  >
                    Refresh
                  </Button>
                </Col>
                <Col md={1}/>
                <Col md={6}>
                  <Upload
                    action={Url.BASE+Url.PLAN+"/files"}
                    accept=".mp3"
                    showUploadList={false}
                  >
                    <Button 
                      type="primary" 
                      shape="round"
                      icon={<UploadOutlined />}
                    >
                      Upload Audio (.mp3)
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </section>
            <section className="ml-5 mt-10">
            <Spin spinning={isLoadList}>
            {fileList.map((file, index) => (
              <Row  className="ml-10 mt-3 pb-3 mr-14" key={index}
              style={{ borderBottom: "#8159f555 solid 2px" }}
              >
                <Col md={1}/>
                <Col md={7}>
                  <Button 
                    type="text" 
                    // shape="square"
                    icon={<PlayCircleOutlined />}
                    onClick={() => {
                      window.open(Url.BASE+Url.PLAN.concat('/files/'+file?.mp3));
                    }}
                  >
                    {(file?.mp3)}
                  </Button>
                </Col>
                {/* <Col md={6}>
                  <h4>{(file?.mp3)}</h4>
                </Col> */}
                <Col md={5}>
                  {/* <h4>{(file?.transcription)}</h4> */}
                  { file?.transcription ? 
                  <Button
                      type="text"
                      shape="round"
                      icon={<FileOutlined />}
                      onClick={() => {
                          viewTextFile( "Transcript of "+file?.mp3, file?.transcription)
                      }}
                  >
                      Transcript
                  </Button> : 
                  <Button
                  type="primary"
                  shape="round"
                  icon={<FileOutlined />}
                  disabled={isTranscribe ? false : true}
                  // style={{
                  //   background: "#8159f5",
                  //   borderColor: "#8159f5",
                  //   color: "white",
                  // }}
                  onClick={() => {
                      transcribeAudio( file?.mp3);
                  }}
                  >
                      Transcribe Audio
                  </Button>
                  }
                </Col>
                <Col md={5}>
                  { (file?.plan)?.map((plan, idx) => (
                    <Row key={idx}>
                      <Col md={16}>
                        {/* <h4>{plan?.name}</h4> */}
                        <Button
                            type="text"
                            shape="round"
                            icon={<FileTextOutlined />}
                            onClick={() => {
                                viewTextFile( "Default Care Plan for "+file?.mp3, plan?.name)
                            }}
                        >
                            Default Care Plan
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col md={5}>
                  {file?.transcription ?
                  <Button
                      type="primary"
                      shape="round"
                      icon={<FileProtectOutlined />}
                      // style={{
                      //   background: "#8159f5",
                      //   borderColor: "#8159f5",
                      //   color: "white",
                      // }}
                      onClick={() => {
                          showGenModal("Generate Care Plan", file?.mp3, file?.transcription);
                      }}
                  >
                      Generate Care Plan
                  </Button> : null
                  }
                </Col>
                <Col md={1}/>
              </Row>
            ))}
            </Spin>
            </section>
            <section
                  className="mt-20 ml-10 mr-10 pt-3 pr-10 pb-1 pl-10 flex text-center items-center justify-center xs:text-[0.4rem] sm:text-[0.5rem] md:text-[0.65rem] lg:text-[1.15rem]"
                  style={{
                    backgroundColor: "rgba(48, 34, 95, 0.75)",
                    color: "white",
                  }}
            
            >
              <Row>
                <Col md={24}>
                    <p>Transcription Server Management</p>
                </Col>
              </Row>
            </section>
            <section className="ml-5 mt-10 mb-10">
              <Row>
                <Col md={1}/>
                <Col md={7}>
                </Col>
                <Col md={7}>
                  <Button 
                    type="primary" 
                    shape="round"
                    icon={<FastForwardOutlined />}
                    disabled={isTranscribe ? true : false}
                    onClick={() => {
                      startstopTranscribe();
                    }}
                  >
                    Start Transcription Server
                  </Button>
                </Col>
                <Col md={7}>
                  <Button 
                    type="primary" 
                    shape="round"
                    icon={<StopOutlined />}
                    disabled={isTranscribe ? false : true}
                    onClick={() => {
                      startstopTranscribe();
                    }}
                  >
                    Stop Transcription Server
                  </Button>
                </Col>
              </Row>              
            </section>
            {/* <PrivacyPolicyModal
                isVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            /> */}
            {/* <Row className="mb-5">
                <Col xs={24} className="flex justify-center">
                    <Button
                        type="primary"
                        onClick={() => { setModalVisible(true); }}
                    >
                        Show modal
                    </Button>
                </Col>
            </Row> */}
            <Footer />
        </main >
    )
  }

  return (
    !isAuthorized ? <PasswordModal handleOk={validatePassword} modalShow={!isAuthorized} onKey={(val) => {setChangekey(val)}}/> :
    <>
      <PlanView />
      <h4>authkey=={authkey}</h4>
    </>
  )
}

