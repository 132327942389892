import React from "react";
import {
  Col,
  Row,
  Form,
  Checkbox,
  Button,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import "./PartnerForm.css";
import GAText from "./GAText";

const { Option } = Select;

const PartnerForm = ({
  isSubmitting = false,
  submitForm = () => {},
  form = undefined,
}) => {
  const onFinish = (values) => {
    submitForm(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Spin spinning={isSubmitting}>
      <Form
        className="partnerForm"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row className="mb-3" gutter={16}>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  First Name
                </label>
              }
              required
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
              name="firstName"
            >
              <Input type="text" placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Last Name
                </label>
              }
              required
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
              name="lastName"
            >
              <Input type="text" placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mb-3" gutter={16}>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Email
                </label>
              }
              required
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
                {
                  required: true,
                  message: "Enter your email",
                },
              ]}
              name="email"
            >
              <Input type="email" placeholder="user@example.com" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Phone (+1)
                </label>
              }
              required
              rules={[
                {
                  pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                  message: "Please check the format of phone number",
                },
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
              name="phone"
            >
              <Input type="text" placeholder="555-555-5555" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="topic"
          label="Area of Inquiry"
          rules={[
            {
              required: true,
              message: "Please select the area of inquiry!",
            },
          ]}
        >

          <Select defaultValue=""  >
            <Option value="I want to learn more about My CareLinq's employee benefit program">
              I want to learn more about My CareLinq's employee benefit program
            </Option>
            <Option value="I want to learn more about elderly care enterprise solutions.">
              I want to learn more about elderly care enterprise solutions
            </Option>
            <Option value="I want to learn more about partnerships">
              I want to learn more about partnerships
            </Option>
            <Option value="Other inquiry">
              Other inquiry
            </Option>
           </Select>
        </Form.Item>

        <Form.Item
          label={
            <label className="text-primary-purple" style={{ fontSize: "1rem" }}>
              Message <br />
              (Please let us know when we can contact you)
            </label>
          }
          rules={[
            { max: 300, message: "Maximum message length is 300 characters." },
          ]}
          name="message"
          className="mb-3"
        >
          <Input.TextArea rows={5} showCount maxLength={300} />
        </Form.Item>
        <div className="mt-6">
        <GAText></GAText>

        </div>
      </Form>
    </Spin>
  );
};

const PartnerFormv2 = ({
  isSubmitting = false,
  submitForm = () => {},
  form = undefined,
}) => {
  const onFinish = (values) => {
    submitForm(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Spin spinning={isSubmitting}>
      <Form
        className="partnerForm"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row className="mb-3" gutter={16}>
          <Col md={16}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Your Name
                </label>
              }
              required
              rules={[
                { required: true, message: "Please enter your full name" },
              ]}
              name="name"
            >
              <Input type="text" placeholder="Full Name" />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Position and Title
                </label>
              }
              required
              rules={[
                { required: true, message: "Please enter your position and title" },
              ]}
              name="position"
            >
              <Input type="text" placeholder="Position" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mb-3" gutter={16}>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Email
                </label>
              }
              required
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
                {
                  required: true,
                  message: "Enter your email",
                },
              ]}
              name="email"
            >
              <Input type="email" placeholder="user@example.com" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Phone (+1)
                </label>
              }
              required
              rules={[
                {
                  pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                  message: "Please check the format of phone number",
                },
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
              name="phone"
            >
              <Input type="text" placeholder="555-555-5555" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mb-3" gutter={16}>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Company Name
                </label>
              }
              required
              rules={[
                { required: true, message: "Please enter your company name" },
              ]}
              name="company"
            >
              <Input type="text" placeholder="Company name" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Company Website
                </label>
              }
              required
              rules={[
                { required: true, message: "Please enter your company's website url" },
                { type: "url", message: "This field must be a valid url." }
      
              ]}
              name="website"
            >
              <Input type="text" placeholder="https://company.com" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={
            <label className="text-primary-purple" style={{ fontSize: "1rem" }}>
              Brief description of your product or service 
            </label>
          }
          rules={[
            { required: true, max: 100, message: "Maximum description length is 100 characters." },
          ]}
          name="product_service"
          className="mb-3"
        >
          <Input.TextArea rows={3} showCount maxLength={100} />
        </Form.Item>

        <Form.Item
          name="partner_type"
          label="Type of Partnership"
          rules={[
            {
              required: true,
              message: "Please select the type of partnership arrangement you are interested in!",
            },
          ]}
        >

          <Select defaultValue=""  >
            <Option value="Client Referrals">
              Client Referrals
            </Option>
            <Option value="Joint Marketing">
              Joint Marketing
            </Option>
            <Option value="Channel Distribution">
              Channel Distribution
            </Option>
            <Option value="Other">
              Other
            </Option>
           </Select>
        </Form.Item>

        <Form.Item
          label={
            <label className="text-primary-purple" style={{ fontSize: "1rem" }}>
              Message (Please let us know when we can contact you)
            </label>
          }
          rules={[
            { required: true, max: 100, message: "Maximum message length is 100 characters." },
          ]}
          name="message"
          className="mb-3"
        >
          <Input.TextArea rows={5} showCount maxLength={100} />
        </Form.Item>
        <div className="mt-6">
        <GAText></GAText>

        </div>
      </Form>
    </Spin>
  );
};


const PartnerModal = ({
  setting = {
    title: "Inquire About Partnership Opportunities",
    isModalVisible: false,
    calendlyLink: "",
  },
  isSubmitting = false,
  handleCancel = () => {},
  submitForm = (values) => {
    console.log(values);
  },
  form = undefined,
}) => {
  return (
    <Modal
      title={setting.title}
      visible={setting.isModalVisible}
      onCancel={handleCancel}
      width={"80%"}
      onOk={form && form.submit}
      footer={[
        <Button
          key="back"
          shape="round"
          onClick={handleCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          shape="round"
          icon={<SendOutlined />}
          // style={{
          //   borderColor: "#8159f5",
          //   color: "#8159f5",
          // }}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={form && form.submit}
        >
          Partner Inquiry
        </Button>,
      ]}
    >
      <PartnerFormv2
        submitForm={submitForm}
        isSubmitting={isSubmitting}
        form={form}
      />
    </Modal>
  );
};

export { PartnerModal, PartnerForm };
